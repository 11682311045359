import * as Yup from 'yup';

import { phoneRegex } from '../utils/utils';

export const ContactSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too short').required('Please provide a full name'),
  email: Yup.string().email().required('Email is required'),
  phone: Yup.string()
    .matches(phoneRegex, 'Invalid Nigerian phone number')
    .required('Phone number is required'),
  message: Yup.string(),
});

export type ContactDataType = Yup.InferType<typeof ContactSchema>;

type InitialValues = {
  name: string;
  email: string;
  phone: string;
  message: string;
};

export const contactInitialValues: InitialValues = {
  name: '',
  email: '',
  phone: '',
  message: '',
};
