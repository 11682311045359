import React, { useState } from 'react';

import styled from '@emotion/styled';

import LeftCheveron from '../Icons/LeftCheveron';
import { ExternalLink, NavLink } from '../Link/Link';

export interface IFooterNavConfigListData {
  label: string;
  path: string;
  icon?: React.ReactNode;
  externalPath?: boolean;
}

interface ICollapseableSegment {
  title: string;
  data: IFooterNavConfigListData[];
  className?: string;
}

const CollapseableSegment: React.FC<ICollapseableSegment> = ({
  title,
  data,
  className,
}) => {
  const [showContent, setShowContent] = useState(false);

  const handleShowContent = () => {
    setShowContent(!showContent);
  };

  return (
    <Container>
      <TitleArea onClick={() => handleShowContent()}>
        <Title
          className={className === 'hamburger-menu' ? 'hamburger-menu' : ''}
        >
          {title}
        </Title>
        <LeftCheveron />
      </TitleArea>
      <RevealContent className={showContent ? 'reveal' : ''}>
        {data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.externalPath ? (
                <ExternalLink to={item.path} className="footer">
                  {item?.icon} {item.label}{' '}
                </ExternalLink>
              ) : (
                <NavLink
                  className={`footer 
              ${className === 'hamburger-menu' ? 'hamburger-menu' : ''}
              `}
                  to={item.path}
                >
                  {item?.icon} {item.label}
                </NavLink>
              )}
            </React.Fragment>
          );
        })}
      </RevealContent>
    </Container>
  );
};

export default CollapseableSegment;

const Container = styled.div`
  display: None;
  flex-direction: column;
  gap: 1.5rem;
  color: ${({ theme }) => theme.palette.secondaryTextColor};
  min-width: 9.4rem;

  @media (max-width: 480px) {
    display: flex;
  }
`;

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* border: 2px solid red; */
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.25rem;

  &.hamburger-menu {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const RevealContent = styled.div`
  /* border: 2px solid yellow; */
  display: none;
  flex-direction: column;
  gap: 1.5rem;
  height: 0;
  transition: all 0.2s ease;

  &.reveal {
    height: fit-content;
    display: flex;
  }

  &.reveal > *.hamburger-menu {
    padding-left: 1rem;
  }
`;
