import {
  BEAUTY_AND_FASHION,
  ECOMMERCE,
  PARTNER,
  RESTAURANT,
  SME,
} from '../routes/routeConstants';

export const NavDropDownData = {
  Industries: [
    {
      title: 'E Commerce',
      link: ECOMMERCE,
      src: '/static/img/cart.png',
      description: 'Elevate Your E-commerce Deliveries with Darum',
      comingSoon: false,
    },
    {
      title: 'SME',
      link: SME,
      src: '/static/img/store.png',
      description: 'Elevate Your E-commerce Deliveries with Darum',

      comingSoon: false,
    },
    {
      title: 'Restaurant',
      link: RESTAURANT,
      src: '/static/img/cutlery.png',
      description: 'Elevate Your E-commerce Deliveries with Darum',
      comingSoon: false,
    },
    {
      title: 'Beauty & Fashion',
      link: BEAUTY_AND_FASHION,
      src: '/static/img/diamondRing.png',
      description: 'Elevate Your E-commerce Deliveries with Darum',
      comingSoon: false,
    },
  ],
  'Products & Services': [
    {
      title: 'Partner',
      link: PARTNER,
      src: '/static/img/handShake.png',
      description:
        'Our strong partner network optimizes delivery capabilities and lowers costs.',
      comingSoon: false,
    },
    {
      title: 'Darum on the Go',
      link: PARTNER,
      src: '/static/img/boxWithArrow.png',
      description:
        'Darum on the Go app simplifies order management for businesses and riders.',
      comingSoon: true,
    },
    {
      title: 'Warehousing',
      link: PARTNER,
      src: '/static/img/house.png',
      description: 'Secure warehousing for streamlined inventory management.',
      comingSoon: true,
    },
    {
      title: 'Referral System',
      link: PARTNER,
      src: '/static/img/shield.png',
      description:
        'Our referral program rewards businesses for expanding our client network.',
      comingSoon: true,
    },
  ],
};
