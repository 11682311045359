import React from 'react';

const PlusIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 0.5C8.89782 0.5 9.27936 0.658035 9.56066 0.93934C9.84196 1.22064 10 1.60218 10 2V7.5H15.5C15.8727 7.49999 16.2321 7.63876 16.5081 7.88925C16.7841 8.13974 16.957 8.48402 16.993 8.855L17 9C17 9.39782 16.842 9.77936 16.5607 10.0607C16.2794 10.342 15.8978 10.5 15.5 10.5H10V16C10 16.3727 9.86124 16.7321 9.61075 17.0081C9.36026 17.2841 9.01598 17.457 8.645 17.493L8.5 17.5C8.10218 17.5 7.72064 17.342 7.43934 17.0607C7.15804 16.7794 7 16.3978 7 16V10.5H1.5C1.12727 10.5 0.767896 10.3612 0.491892 10.1108C0.215888 9.86026 0.0430302 9.51598 0.00699997 9.145L0 9C0 8.60218 0.158035 8.22064 0.43934 7.93934C0.720644 7.65804 1.10218 7.5 1.5 7.5H7V2C6.99999 1.62727 7.13876 1.2679 7.38925 0.991892C7.63974 0.715888 7.98402 0.54303 8.355 0.507L8.5 0.5Z"
        fill="#343538"
      />
    </svg>
  );
};

export default PlusIcon;
