import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

// === interfaces
interface IDropDown {
  children: React.ReactNode;
  label: any;
  labelTo?: string;
}

const DropDownTemplate: React.FC<IDropDown> = ({
  label,
  children,
  labelTo,
}) => {
  return (
    <DropDownContainer>
      {labelTo ? (
        <DropDownLabel to={labelTo}>{label}</DropDownLabel>
      ) : (
        <DropDownLabelWithoutTo>{label}</DropDownLabelWithoutTo>
      )}
      <DropDownContentWrapper className="dropDownContent">
        {children}
      </DropDownContentWrapper>
    </DropDownContainer>
  );
};

export const HoverDropDown: React.FC<IDropDown> = ({
  children,
  label,
  labelTo,
}) => {
  return (
    <DropDownTemplate label={label} labelTo={labelTo}>
      {children}
    </DropDownTemplate>
  );
};

const DropDownContainer = styled.div`
  position: relative;
  cursor: pointer;
  /* border: 2px solid yellow; */
  /* border: 2px solid red; */

  &:hover > div.dropDownContent {
    opacity: 1;
    pointer-events: auto;
  }
`;

const DropDownLabelWithoutTo = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black};
  font-weight: 500;
  display: flex;
  gap: 0.5rem;
`;

const DropDownLabel = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black};
  font-weight: 500;
  display: flex;
  gap: 0.5rem;
`;

const DropDownContentWrapper = styled.div`
  position: absolute;
  box-shadow: 0px 8px 16px -2px rgba(46, 58, 158, 0.05);
  display: flex;
  padding: 2rem;
  border-radius: 1rem;
  background-color: white;
  overflow: hidden;
  left: -334.4px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  z-index: 50000;
`;
