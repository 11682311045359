import React from 'react';

import styled from '@emotion/styled';

import FAQ from '../../components/FAQ.tsx/FAQ';
import Page from '../../components/Page/Page';
import ContactForm from './ContactForm.tsx/ContactForm';

const ContactUs = () => {
  return (
    <Page title="Darum | Contact Us">
      <Container>
        <ContactForm />;
        <FAQ />
      </Container>
    </Page>
  );
};

export default ContactUs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* border: 1px solid blue; */
  margin: auto;
`;
