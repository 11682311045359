import React from 'react';

import PointsCard from '../../../../components/Card/PointsCard';

const SolutionsWeOfferData = [
  {
    title: 'Swift Last-Mile Delivery:',
    description:
      'Our efficient routing and dedicated riders ensure your e-commerce orders reach your customers swiftly, reducing delivery times and cart abandonment.',
  },
  {
    title: 'Real-time Tracking',
    description:
      'Enhance customer experience with real-time package tracking, offering transparency and peace of mind to online shoppers.',
  },
  {
    title: 'Cost Optimization',
    description:
      'Reduce delivery costs with our cost-effective models and resource-sharing solutions designed specifically for e-commerce.',
  },
];
const SolutionsWeOffer = () => {
  return (
    <PointsCard
      header="Solutions We Offer"
      src="/static/img/blondeHair.png"
      data={SolutionsWeOfferData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 0rem"
    />
  );
};

export default SolutionsWeOffer;
