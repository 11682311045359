import React from 'react';

import styled from '@emotion/styled';

import Img from '../Img/Img';

interface IHStackCard {
  header: string;
  description: string;
  src: string;
  className?: string;
}
const HStackCard: React.FC<IHStackCard> = ({
  header,
  description,
  src,
  className,
}) => {
  return (
    <Container className={className}>
      <TextArea>
        <Header>{header}</Header>
        <Description className={className}>{description}</Description>
      </TextArea>
      <ImgWrapper>
        <Img src={src} />
      </ImgWrapper>
    </Container>
  );
};

export default HStackCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  border: 1px solid #ebebeb;

  &.horizontalScrollView {
    font-size: 0.875rem;
    color: #343538;
    border-radius: 1rem;
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.brandBackdrop};
  }
`;

const TextArea = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  /* border: 2px solid red; */
  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

const Header = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4e4e65;

  &.horizontalScrollView {
    font-size: 0.875rem;
    color: #343538;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & > img {
    width: 100%;
  }
`;
