import { css, Theme } from '@emotion/react';

declare module '@emotion/react' {
  export interface Theme {
    palette: {
      primaryButtonColor: string;
      white: string;
      black: string;
      secondaryTextColor: string;
      primaryTextColor: string;
      brandBackdrop: string;
      brandPrimary: string;
      borderColor: string;
      accordionColor: string;
      pointCardColor: string;
    };
  }
}

export const theme: Theme = {
  palette: {
    primaryButtonColor: '#F46F3B',
    white: '#FFFFFF',
    black: '#000000',
    secondaryTextColor: '#504B49',
    primaryTextColor: '#2C2421',
    brandBackdrop: '#FEF8F5',
    brandPrimary: '#F46F3B',
    borderColor: '#E8E9EB',
    accordionColor: '#424242',
    pointCardColor: '#07071D',
  },
};

const GlobalStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
    font-size: 1em;
  }
  html,
  body {
    height: 100%;
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: 'Manrope', 'Work Sans', sans-serif;
    font-weight: 500;
    color: #867c79;
  }
  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  #root,
  #__next {
    isolation: isolate;
  }
`;

export default GlobalStyles;
