import React from 'react';

import styled from '@emotion/styled';

import FAQ from '../../components/FAQ.tsx/FAQ';

const FAQs = () => {
  return (
    <Container>
      <FAQ />;
    </Container>
  );
};

export default FAQs;

const Container = styled.div`
  display: flex;
  /* border: 2px solid red; */
  margin: auto;
  margin-bottom: 6rem;
`;
