import React from 'react';

import styled from '@emotion/styled';

import PrimaryButton from '../Button/PrimaryButton';
import Img from '../Img/Img';
import { ExternalLink } from '../Link/Link';

interface IHeroCard {
  src: string;
  header: string;
  description: string;
  btnText: string;
  btnLink: string;
  className?: string;
}
const HeroCard: React.FC<IHeroCard> = ({
  src,
  header,
  description,
  btnText,
  btnLink,
  className,
}) => {
  return (
    <Container>
      <InfoArea className={className === 'reverse' ? className : ''}>
        <Header>{header}</Header>
        <Description>{description}</Description>
        <ExternalLink to={btnLink}>
          <PrimaryButton>{btnText}</PrimaryButton>
        </ExternalLink>
      </InfoArea>
      <ImgWrapper className={className === 'reverse' ? className : ''}>
        <Img src={src} />
      </ImgWrapper>
    </Container>
  );
};

export default HeroCard;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.primaryTextColor};

  @media (max-width: 480px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */

  @media (max-width: 480px) {
    font-size: 2rem;
    gap: 1rem;
  }

  &.reverse {
    grid-row: 1;
    grid-column: 2;
  }

  & > a {
    margin-top: 2rem;
    @media (max-width: 480px) {
      margin-top: 1rem;
    }
  }
`;

const Header = styled.h1`
  font-size: 3.3rem;
  font-weight: 800;

  @media (max-width: 480px) {
    font-size: 1.79rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.secondaryTextColor};

  @media (max-width: 480px) {
    font-size: 1.125rem;
    font-weight: 600;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: left;
  /* border: 2px solid blue; */
  &.reverse {
    grid-column: 1;
    grid-row: 1;
  }

  & > img {
    /* border: 2px solid red; */
    width: 100%;
  }
`;
