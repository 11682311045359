import React from 'react';

import styled from '@emotion/styled';

import BeatTheTraffic from '../../components/BeatTheTraffic/BeatTheTraffic';
import { HeroCard } from '../../components/Card';
import FAQ from '../../components/FAQ.tsx/FAQ';
// import HearFromBusinessLikeYours from '../../components/HearFromBusinessLikeYours/HearFromBusinessLikeYours';
import InfiniteScrolling from '../../components/InfiniteScrolling/InfiniteScrolling';
import Page from '../../components/Page/Page';
import OurServices from './OurServices/OurServices';
import WhyChooseUs from './WhyChooseUs/WhyChooseUs';

const Home = () => {
  return (
    <Page title="Darum | Home">
      <Container>
        <HeroCard
          src="/static/img/receivingPackage.png"
          header="Darum Logistics: Redefining Fast, Safe, & Cost-effective Delivery"
          description="Making Every Movement Count"
          btnLink="https://business.darum.com.ng"
          btnText="Get Started"
          // className="reverse"
        />
        <InfiniteScrolling title="Trusted By" />
        <OurServices />
        <WhyChooseUs />
        {/* <HearFromBusinessLikeYours /> */}
        <FAQ />
        <BeatTheTraffic
          title="Beat the traffic"
          link="https://business.darum.com.ng"
          buttonLabel="Get Started"
          description="Lagos traffic has nothing on you! We'll make sure that your package is delivered to the customers swiftly as two wheels can go."
        />
      </Container>
    </Page>
  );
};

export default Home;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 3.03rem;
  /* outline: 2px solid red; */

  @media (max-width: 480px) {
    margin-top: 1.88rem;
  }
`;
