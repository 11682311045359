import React from 'react';

import styled from '@emotion/styled';

const NotFound = () => {
  return <Container>404 NotFound</Container>;
};

export default NotFound;

const Container = styled.div`
  display: flex;
  font-size: 4rem;
  font-weight: 800;
  color: ${({ theme }) => theme.palette.primaryButtonColor};
  align-items: center;
  justify-content: center;
`;
