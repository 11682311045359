import React from 'react';

import styled from '@emotion/styled';

import Img from '../Img/Img';

interface IDropDownCell {
  src: string;
  title: string;
  description: string;
  comingSoon: boolean;
}

const DropDownCell: React.FC<IDropDownCell> = ({
  src,
  title,
  description,
  comingSoon,
}) => {
  return (
    <DropDownContentCell>
      <ImgWrapper>
        <Img src={src} />
      </ImgWrapper>
      <DropDownContentCellTextArea>
        <DropDownContentTitle>{title}</DropDownContentTitle>
        <DropDownContentDescription>{description}</DropDownContentDescription>
        {comingSoon && <DropDownBadge>Coming Soon</DropDownBadge>}
      </DropDownContentCellTextArea>
    </DropDownContentCell>
  );
};

export default DropDownCell;

const DropDownContentCell = styled.div`
  padding: 0.5rem 1rem;
  width: 22.4rem;
  border: 1px solid transparent;

  display: grid;
  grid-template-columns: 3.5rem auto;
  align-items: flex-start;
  gap: 1.25rem;

  &:hover {
    border: 1px dashed ${({ theme }) => theme.palette.borderColor};
  }
`;

const DropDownBadge = styled.div`
  color: #139ab7;
  font-size: 0.75rem;
  font-weight: 400;
  background: #f1fbfe;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  width: fit-content;
`;
const DropDownContentCellTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DropDownContentTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  color: #504b49;
`;

const DropDownContentDescription = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: #71748c;
`;

const ImgWrapper = styled.div`
  /* border: 2px solid green; */
  padding: 0.5rem;
  border-radius: 0.625rem;
  background: ${({ theme }) => theme.palette.brandBackdrop};

  & > img {
    width: fit-content;
    height: fit-content;
  }
`;
