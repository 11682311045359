import React, { useReducer } from 'react';

import styled from '@emotion/styled';

import { DownCheveron } from '../Icons/DownCheveron';

interface IAccordionBox {
  title: string;
  children: any;
  handleStateChange?: any;
  index?: number;
  isOpen?: boolean;
}

// Type defination
export enum AccordionBoxReducerActionType {
  CLICKED_SECTION = 'section-clicked',
}

interface IAccordionBoxReducerActionType {
  type: AccordionBoxReducerActionType;
  payload: any;
}

// Reducers
function accordionBoxReducer(
  state: any,
  action: IAccordionBoxReducerActionType
) {
  switch (action.type) {
    case AccordionBoxReducerActionType.CLICKED_SECTION:
      return { ...action.payload };
    default:
      return state;
  }
}

// Components

export const AccordionBox: React.FC<Pick<IAccordionBox, 'children'>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(accordionBoxReducer, {
    index: -1,
  });

  const handleStateChange = (index: any) => {
    dispatch({
      type: AccordionBoxReducerActionType.CLICKED_SECTION,
      payload: { index },
    });
  };

  return (
    <AccordionBoxContainer>
      {React.Children.map(children, (child: any, index) => {
        const isOpen = index === state.index ? true : false;
        return React.cloneElement(child, {
          ...child.props,
          handleStateChange,
          index,
          isOpen,
        });
      })}
    </AccordionBoxContainer>
  );
};

export const AccordionBoxCell: React.FC<IAccordionBox> = ({
  title,
  children,
  handleStateChange,
  isOpen,
  index,
}) => {
  return (
    <AccordionBoxCellWrapper className={isOpen ? 'revealPadding' : ''}>
      <AccordionBoxLabel
        onClick={() => handleStateChange(index)}
        className={isOpen ? 'reveal' : ''}
      >
        <Label>{title}</Label>
        <DownCheveron />
      </AccordionBoxLabel>
      <RevealContent className={isOpen ? 'reveal' : ''}>
        {children}
      </RevealContent>
    </AccordionBoxCellWrapper>
  );
};

// Styles

const AccordionBoxCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.accordionColor};

  &.revealPadding {
    /* gap: 1.25rem; */
  }
`;
const AccordionBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 5.34rem;
`;

const AccordionBoxLabel = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 1.8rem;
  align-items: center;
  cursor: pointer;

  border: 2px solid ${({ theme }) => theme.palette.borderColor};
  border-radius: 0.5rem;
  padding: 1.5rem;

  &.reveal {
    border: 2px solid ${({ theme }) => theme.palette.brandPrimary};
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
`;

const RevealContent = styled.div`
  display: inline block;
  padding: 0rem;
  height: 0;
  overflow-y: auto;
  transition: all 0.2s ease;

  &.reveal {
    height: fit-content;
    padding-bottom: 1rem;
  }
`;
