import React from 'react';

import styled from '@emotion/styled';

import CollapseableSegment from '../../components/CollapseableSegment/CollapseableSegment';
import { FooterNavConfig } from './FooterNavConfig';
import FooterNavSegment from './FooterNavSegment';

const FooterNav = () => {
  return (
    <Container>
      {/* Mobile only start */}
      <CollapseableSegment title="Learn" data={FooterNavConfig.Learn} />
      <CollapseableSegment title="Company" data={FooterNavConfig.Learn} />
      <CollapseableSegment title="Industries" data={FooterNavConfig.Learn} />
      {/* Mobile only end */}
      {/* Web only start */}
      <FooterNavSegment title="Learn" data={FooterNavConfig.Learn} />
      <FooterNavSegment title="Company" data={FooterNavConfig.Company} />
      <FooterNavSegment title="Industries" data={FooterNavConfig.Industries} />
      {/* Web only end */}
      <FooterNavSegment
        title="Contact"
        data={FooterNavConfig.Contact}
        showSocials
      />
    </Container>
  );
};

export default FooterNav;

const Container = styled.div`
  /* border: 2px solid blue; */
  width: 100%;
  display: flex;
  gap: 5.8rem;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 2.31rem;
  }
`;
