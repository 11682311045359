import React from 'react';

import styled from '@emotion/styled';

import { Accordion, AccordionCell } from '../Accordion/Accordion';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import {
    SegmentedView, SegmentedViewController, SegmentedViewData
} from '../SegmentedViewController';
import { AccordionCellData, segmentedViewControllerTitle } from './FAQAccordionData';

const LargeScreenFAQ = () => {
  return (
    <Container>
      <SectionWrapper
        margin="2rem 0"
        title="Frequently asked Questions"
        description="Still do not get the full picture? It is ok, we got you! Please read on."
      >
        <SegmentedView>
          <SegmentedViewController
            segmentedViewControllerTitle={segmentedViewControllerTitle}
          />
          <SegmentedViewData>
            <div>
              <Accordion>
                {AccordionCellData.Businesses.map((data, index) => (
                  <AccordionCell key={index} title={data.title}>
                    {data.conntent}
                  </AccordionCell>
                ))}
              </Accordion>
            </div>
            <div>
              <Accordion>
                {AccordionCellData.Riders.map((data, index) => (
                  <AccordionCell key={index} title={data.title}>
                    {data.conntent}
                  </AccordionCell>
                ))}
              </Accordion>
            </div>
            <div>
              <Accordion>
                {AccordionCellData['Delivery Startups'].map((data, index) => (
                  <AccordionCell key={index} title={data.title}>
                    {data.conntent}
                  </AccordionCell>
                ))}
              </Accordion>
            </div>
          </SegmentedViewData>
        </SegmentedView>
      </SectionWrapper>
    </Container>
  );
};

export default LargeScreenFAQ;

const Container = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;
