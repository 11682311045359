import React from 'react';

import BulletCard from '../../../../components/Card/BulletCard';

const WhyPartnerWithDarumData = [
  {
    title: 'Better outreach',
    description:
      'Expand your horizons with Darum, get your logistics business exposed to a wider range of customers.',
  },
  {
    title: 'Healthy competition',
    description:
      'Our platform with an array of logistics service providers allows for healthy comparison and competition to ensure better delivery experiences.',
  },
  {
    title: 'Riders training',
    description:
      'We train riders periodically to ensure a dynamic experience for every delivery.',
  },
  {
    title: 'More profit',
    description:
      'You will smile to the bank more often, as DARUM ensures that you get more deliveries.',
  },
  {
    title: 'Access to new routes',
    description:
      'Routes optimization allows partners access to places otherwise scarcely charted.',
  },
  {
    title: 'Track your riders',
    description:
      'You can know the exact location of your riders at every point in time from the DARUM web app.',
  },
];
const WhyPartnerWithDarum = () => {
  return (
    <BulletCard
      header="Why partner with Darum"
      src="/static/img/snapPackage.png"
      data={WhyPartnerWithDarumData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 4.75rem"
    />
  );
};

export default WhyPartnerWithDarum;
