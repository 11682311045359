import React from 'react';

import styled from '@emotion/styled';

import { HeroCard } from '../../../components/Card';
import FAQ from '../../../components/FAQ.tsx/FAQ';
// import HearFromBusinessLikeYours from '../../../components/HearFromBusinessLikeYours/HearFromBusinessLikeYours';
import Page from '../../../components/Page/Page';
import ProblemsWeSolve from './ProblemsWeSolve/ProblemsWeSolve';
import SolutionsWeOffer from './SolutionsWeOffer/SolutionsWeOffer';

const BeautyAndFashion = () => {
  return (
    <Page title="Darum | Beauty & Fashion">
      <Container>
        <HeroCard
          src="/static/img/curlyHairWoman.png"
          header="Deliver Style and Beauty with Confidence"
          description="Fast and Secure Delivery Solutions for Style and Glamour"
          btnLink="https://business.darum.com.ng"
          btnText="Get Started"
        />
        <SolutionsWeOffer />
        <ProblemsWeSolve />
        {/* <HearFromBusinessLikeYours /> */}
        <FAQ />
      </Container>
    </Page>
  );
};

export default BeautyAndFashion;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 3.03rem;

  @media (max-width: 480px) {
    margin-top: 1.88rem;
  }
`;
