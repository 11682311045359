import React from 'react';

const TwitterIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1372 0.0900879H3.22C1.79583 0.0900879 0.640381 1.24554 0.640381 2.66971V21.5869C0.640381 23.0111 1.79583 24.1665 3.22 24.1665H22.1372C23.5614 24.1665 24.7168 23.0111 24.7168 21.5869V2.66971C24.7168 1.24554 23.5614 0.0900879 22.1372 0.0900879ZM19.5092 8.62432C19.52 8.7748 19.52 8.93065 19.52 9.08113C19.52 13.7406 15.973 19.1094 9.49169 19.1094C7.49249 19.1094 5.63839 18.529 4.07987 17.5294C4.3647 17.5616 4.63879 17.5724 4.929 17.5724C6.57888 17.5724 8.0944 17.0135 9.3036 16.0676C7.75583 16.0354 6.45527 15.0196 6.00921 13.6223C6.55201 13.7029 7.04106 13.7029 7.59998 13.5578C5.98771 13.23 4.77852 11.8112 4.77852 10.0969V10.0539C5.24607 10.3172 5.79424 10.4784 6.36928 10.4999C5.88583 10.1783 5.48952 9.74209 5.21569 9.23008C4.94187 8.71807 4.79906 8.14624 4.80001 7.56561C4.80001 6.90995 4.97199 6.30804 5.27832 5.78674C7.01419 7.92568 9.62068 9.32297 12.5442 9.47345C12.0444 7.08193 13.8341 5.14184 15.9837 5.14184C16.9995 5.14184 17.9131 5.5664 18.558 6.2543C19.3534 6.10382 20.1165 5.80824 20.7936 5.40518C20.5303 6.22205 19.9768 6.90995 19.2459 7.34526C19.9553 7.27002 20.6432 7.07118 21.2773 6.79709C20.799 7.50112 20.1971 8.12452 19.5092 8.62432Z"
        fill="#504B49"
      />
    </svg>
  );
};

export default TwitterIcon;
