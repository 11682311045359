import React from 'react';

import styled from '@emotion/styled';

import { ISectionWrapper } from './Interface';

const SectionWrapper: React.FC<ISectionWrapper> = ({
  title,
  description,
  children,
  margin,
  margin_sm,
}) => {
  return (
    <Container margin={margin} margin_sm={margin_sm}>
      <HeaderArea>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </HeaderArea>
      {children}
    </Container>
  );
};

export default SectionWrapper;

interface IContainer {
  margin?: string;
  margin_sm?: string;
}
const Container = styled.section<IContainer>`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  /* border: 2px solid blue; */
  color: ${({ theme }) => theme.palette.primaryTextColor};
  margin: ${({ margin }) => (margin ? margin : '0')};

  @media (max-width: 480px) {
    gap: 2.5rem;
    margin: ${({ margin_sm }) => (margin_sm ? margin_sm : '0')};
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 480px) {
    gap: 1rem;
  }
`;
