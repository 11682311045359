import React from 'react';

import styled from '@emotion/styled';

import PrimaryButton from '../../components/Button/PrimaryButton';
import { FullTextLogo } from '../../components/CompanyLogo';
import HamburgerIcon from '../../components/Icons/HamburgerIcon';
import { ExternalLink, NavLink } from '../../components/Link/Link';
import Nav from './Nav';

interface IHeader {
  open: boolean;
  toggleNav: (...arg: any) => void;
}

const Header: React.FC<IHeader> = ({ open, toggleNav }) => {
  return (
    <Container>
      <NavLink to="/">{!open && <FullTextLogo className="header" />}</NavLink>
      <Nav />
      <HamburgerIconContainer onClick={() => toggleNav()}>
        <HamburgerIcon />
      </HamburgerIconContainer>
      <ExternalLink
        to="https://business.darum.com.ng"
        className="header_nav__mobile"
      >
        <PrimaryButton>Get Started</PrimaryButton>
      </ExternalLink>
    </Container>
  );
};

const Container = styled.div`
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 4rem;

  @media (max-width: 480px) {
    /* border: 2px solid red; */
    padding: 1rem;
  }
`;

const HamburgerIconContainer = styled.div`
  display: None;
  @media (max-width: 480px) {
    display: block;
    cursor: pointer;
  }
`;

export default Header;
