import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import styled from '@emotion/styled';

export const NavLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.black};
  font-weight: 500;
  display: flex;
  gap: 0.5rem;

  &.footer {
    color: ${({ theme }) => theme.palette.secondaryTextColor};
    font-weight: 600;
  }

  &.hamburger-menu {
    font-size: 1rem;
    font-weight: 600;
    /* color: ${({ theme }) => theme.palette.secondaryTextColor}; */
    color: black;
  }
`;

interface IExternalLink {
  children: React.ReactNode;
  to: string;
  className?: string;
}

export const ExternalLink: React.FC<IExternalLink> = ({
  children,
  to,
  className,
}) => {
  return (
    <AnchorTag
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </AnchorTag>
  );
};

export const AnchorTag = styled.a`
  text-decoration: none;
  font-weight: 500;

  &.footer {
    display: grid;
    grid-template-columns: 1.5rem auto;
    gap: 0.5rem;
    color: ${({ theme }) => theme.palette.secondaryTextColor};
    font-weight: 600;
  }

  &.header_nav__mobile {
    @media (max-width: 480px) {
      display: None;
    }
  }
`;

export const ButtonLink = styled(RouterLink)`
  text-decoration: none;
  font-weight: 500;
`;

interface INavItem {
  icon: React.ReactNode;
  children: string;
  path: string;
}

export const NavBoxItem: React.FC<INavItem> = ({ icon, path, children }) => {
  return (
    <NavigationBoxLink
      className={(navData) => (navData.isActive ? 'active' : '')}
      to={path}
    >
      {icon}
      {children}
    </NavigationBoxLink>
  );
};

export const NavigationBoxLink = styled(RouterLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primaryButtonColor};
  display: flex;
  gap: 1.25rem;

  padding: 0.81rem 1.31rem;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  &:hover {
    /* background-color: #d2dfff28; */
    color: ${({ theme }) => theme.palette.primaryButtonColor};
  }

  &.active {
    background-color: #2858d128;
    color: ${({ theme }) => theme.palette.primaryButtonColor};
  }
`;
