import React from 'react';

const EmailIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1182 4.08984H4.11816C3.01816 4.08984 2.12816 4.98984 2.12816 6.08984L2.11816 18.0898C2.11816 19.1898 3.01816 20.0898 4.11816 20.0898H20.1182C21.2182 20.0898 22.1182 19.1898 22.1182 18.0898V6.08984C22.1182 4.98984 21.2182 4.08984 20.1182 4.08984ZM20.1182 8.08984L12.1182 13.0898L4.11816 8.08984V6.08984L12.1182 11.0898L20.1182 6.08984V8.08984Z"
        fill="#504B49"
      />
    </svg>
  );
};

export default EmailIcon;
