import React from 'react';

import styled from '@emotion/styled';

import { SharingPolicyData } from './SharingPolicyData';

const SharingPolicy = () => {
  return (
    <Container>
      <Header>Sharing Policy</Header>
      <UpperUl>
        {SharingPolicyData.map((data, index) => (
          <ContentArea key={index}>
            <UpperUlChild>{data.segmentName}</UpperUlChild>
            <LowerUl>
              {data.data.map((subData, index) => (
                <LowerUlChild key={index}>{subData}</LowerUlChild>
              ))}
            </LowerUl>
          </ContentArea>
        ))}
      </UpperUl>
    </Container>
  );
};

export default SharingPolicy;

const Container = styled.div`
  display: flex;
  margin-top: 4.44rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.palette.primaryTextColor};
  font-size: 3.5rem;
  font-weight: 800;

  @media (max-width: 480px) {
    font-size: 1.75rem;
    font-weight: 800;
    text-align: center;
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const UpperUl = styled.ul`
  counter-reset: section;
  list-style: none;
  padding: 0rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const UpperUlChild = styled.li`
  color: #07071d;
  font-size: 1.75rem;
  font-weight: 600;

  &::before {
    content: counters(section, '.') '. ';
    counter-increment: section;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
    font-weight: 600;
  }
`;

const LowerUl = styled.ul`
  counter-reset: subsection;
  list-style: none;
  color: #4e4e65;
  padding: 0rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const LowerUlChild = styled.li`
  &::before {
    content: counter(section) '.' counters(subsection, '.') ' ';
    counter-increment: subsection;

    font-weight: 700;
  }
`;
