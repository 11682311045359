import React from 'react';

import PointsCard from '../../../../components/Card/PointsCard';

const SolutionsWeOfferData = [
  {
    title: 'Hot and Fresh Food Deliveries',
    description:
      "Keep your restaurant's reputation intact with our specialized services for delivering hot and fresh meals to your customers.",
  },
  {
    title: 'Timely Deliveries',
    description:
      'We understand that punctuality is crucial in the restaurant business. Our riders ensure your food reaches customers promptly.',
  },
  {
    title: 'Enhanced Customer Satisfaction',
    description:
      'Our professional and reliable delivery service contributes to improved customer satisfaction and loyalty.',
  },
];
const SolutionsWeOffer = () => {
  return (
    <PointsCard
      header="Solutions We Offer"
      src="/static/img/pizzaDelivery.png"
      data={SolutionsWeOfferData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 0rem"
    />
  );
};

export default SolutionsWeOffer;
