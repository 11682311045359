import React from 'react';

import styled from '@emotion/styled';

import Img from '../Img/Img';

interface Idata {
  title: string;
  description: string;
}

interface IBulletCard {
  header: string;
  src: string;
  data: Idata[];
  margin?: string;
  margin_sm?: string;
}
const BulletCard: React.FC<IBulletCard> = ({
  header,
  src,
  data,
  margin,
  margin_sm,
}) => {
  return (
    <Container margin={margin} margin_sm={margin_sm}>
      <ImgWrapper>
        <Img src={src} />
      </ImgWrapper>
      <TextArea>
        <CardTextLabel>{header}</CardTextLabel>
        <TextContainer>
          {data.map((item, index) => (
            <TextCell key={index}>
              <Header>{item.title}</Header>
              <Description>{item.description}</Description>
            </TextCell>
          ))}
        </TextContainer>
      </TextArea>
    </Container>
  );
};

export default BulletCard;

interface IContainer {
  margin?: string;
  margin_sm?: string;
}

const Container = styled.section<IContainer>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 100%;
  gap: 4.81rem;
  color: ${({ theme }) => theme.palette.pointCardColor};
  border-radius: 3.125rem;
  background-color: ${({ theme }) => theme.palette.brandBackdrop};
  padding: 5.06rem 1.45rem;
  margin: ${({ margin }) => (margin ? margin : '0')};

  @media (max-width: 480px) {
    border-radius: 1rem;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    margin: ${({ margin_sm }) => (margin_sm ? margin_sm : '0')};
  }
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 2px solid red; */
  gap: 1rem;
`;

const CardTextLabel = styled.h2`
  font-size: 2rem;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 1.6rem;
    text-align: center;
  }
`;

const TextContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TextCell = styled.li`
  /* border: 2px solid red; */
  position: relative;
  list-style: none;

  &::before {
    content: url('/static/svg/checkMark.svg');
    position: absolute;
    left: -40px;
    top: calc(0% + 8px);
  }
`;

const Header = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
`;

const Description = styled.p`
  font-size: 1.125rem;
  color: ${({ theme }) => theme.palette.secondaryTextColor};

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & > img {
    width: 100%;
  }
`;
