import React, { useState } from 'react';

import styled from '@emotion/styled';

import { FormSubmitButton } from '../../../components/Button/PrimaryButton';
import { FormComponent } from '../../../components/FormCom/FormComponent';
import {
  TextAreaInputField,
  TextInputField,
} from '../../../components/FormCom/FormField';
import {
  ContactDataType,
  contactInitialValues,
  ContactSchema,
} from '../../../models/Contact.model';

const ContactForm = () => {
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const onSubmit = async (values: ContactDataType) => {
    setLoadingSpinner(true);
    console.log('contact data', values);
  };
  return (
    <Container>
      <SectionLabel>Contact Us</SectionLabel>
      <FormComponent
        initialValues={contactInitialValues}
        schema={ContactSchema}
        onSubmit={onSubmit}
      >
        <TextInputField
          label="Name"
          name="name"
          type="text"
          placeholder="Add Location Name"
        />

        <TextInputField
          label="Email"
          name="email"
          type="email"
          placeholder="Email"
        />
        <TextInputField
          label="Phone Number"
          name="phone"
          type="text"
          placeholder="Phone Number"
        />
        <TextAreaInputField
          label="message"
          name="message"
          type="textArea"
          placeholder="Write Message"
        />

        <FormSubmitButton
          className="spanTwo"
          type="submit"
          showIcon={loadingSpinner}
        >
          Send Messages
        </FormSubmitButton>
      </FormComponent>
    </Container>
  );
};

export default ContactForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2.94rem;
  gap: 1rem;
`;

const SectionLabel = styled.h1`
  display: flex;
  color: #2c2421;
  text-align: center;
  font-size: 3.5rem;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;
