import EmailIcon from '../../components/Icons/EmailIcon';
import LocationIcon from '../../components/Icons/LocationIcon';
import PhoneIcon from '../../components/Icons/PhoneIcon';
import {
    BEAUTY_AND_FASHION, CONTACT_US, ECOMMERCE, FAQ, INDEMNITY_POLICY, PARTNER, PRIVACY_AND_POLICY,
    RESTAURANT, SHARING_POLICY, SME, TERM_AND_CONDITION
} from '../../routes/routeConstants';

export interface IFooterNavConfigListData {
  label: string;
  path: string;
  icon?: React.ReactNode;
  externalPath?: boolean;
}

export interface IFooterNavConfig {
  Learn: IFooterNavConfigListData[];
  Company: IFooterNavConfigListData[];
  Industries: IFooterNavConfigListData[];
  Contact: IFooterNavConfigListData[];
}

export const FooterNavConfig: IFooterNavConfig = {
  Learn: [
    { label: 'Become a Partner', path: PARTNER },
    { label: 'Indemnity Policy', path: INDEMNITY_POLICY },
    { label: 'Sharing Policy', path: SHARING_POLICY },
    { label: 'Terms & Policies', path: TERM_AND_CONDITION },
    { label: 'Privacy Policies', path: PRIVACY_AND_POLICY },
    { label: 'FAQ', path: FAQ },
  ],
  Company: [
    // { label: 'Services', path: PRODUCTS_AND_SERVICES },
    // { label: 'About Us', path: ABOUT_US },
    // { label: 'Blog', path: BLOG },
    { label: 'Contact us', path: CONTACT_US },
  ],
  Industries: [
    { label: 'Ecommerce', path: ECOMMERCE },
    { label: 'SME', path: SME },
    { label: 'Restaurant', path: RESTAURANT },
    { label: 'Beauty & Fashion', path: BEAUTY_AND_FASHION },
  ],
  Contact: [
    {
      label: '23, Hughes avenue, Alagomeji, Yaba, Lagos',
      path: '/',
      icon: <LocationIcon />,
    },
    {
      // label: '07000032786',
      label: '0700000DARUM',
      path: 'tel:0700000DARUM',
      externalPath: true,
      icon: <PhoneIcon />,
    },
    {
      label: 'info@darum.com.ng',
      path: 'mailto:info@darum.com.ng',
      externalPath: true,
      icon: <EmailIcon />,
    },
  ],
};
