import React from 'react';

import PointsCard from '../../../../components/Card/PointsCard';

const ProblemsWeSolveData = [
  {
    title: 'Style and Beauty Trends',
    description:
      "Trends change rapidly in fashion and beauty. Our speedy deliveries help you stay ahead, delivering the latest styles and products when they're in demand.",
  },
  {
    title: 'Product Safety',
    description:
      "Fashion and beauty items can be delicate. Darum's secure packaging ensures they arrive in perfect condition, protecting your investment in quality products.",
  },
  {
    title: 'Brand Reputation',
    description:
      "In the fashion and beauty industries, reputation is everything. Darum's professional and reliable delivery service contributes to enhancing your brand image and trustworthiness.",
  },
];
const ProblemsWeSolve = () => {
  return (
    <PointsCard
      header="Problems We Solve"
      // blondeHair
      src="/static/img/dressingDoll.png"
      data={ProblemsWeSolveData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 0rem"
      className="reverse"
    />
  );
};

export default ProblemsWeSolve;
