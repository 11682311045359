import LargeScreenFAQ from './LargeScreenFAQ';
import SmallScreenFAQ from './SmallScreenFAQ';

const FAQ = () => {
  return (
    <>
      <LargeScreenFAQ />
      <SmallScreenFAQ />
    </>
  );
};

export default FAQ;
