import { keyframes } from '@emotion/react';
import RefreshIcon from '@mui/icons-material/Refresh';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinningIcon: React.FC<ISpinningIcon> = ({ color, size }) => {
  const fontSize = size;
  return (
    <RefreshIcon
      sx={{
        animation: `${spinAnimation} 1s linear infinite`,
        color: { color },
        fontSize: { fontSize },
      }}
    />
  );
};

interface ISpinningIcon {
  color: string;
  size: number;
}
