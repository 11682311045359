import React from 'react';

const PhoneIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1152 20.0898C10.5842 20.1018 4.10917 13.5498 4.11817 5.09284C4.11817 4.53984 4.56617 4.08984 5.11817 4.08984H7.75817C8.25317 4.08984 8.67417 4.45384 8.74717 4.94384C8.92128 6.1189 9.26309 7.26289 9.76217 8.34084L9.86517 8.56284C9.93436 8.71223 9.94925 8.88109 9.90726 9.04028C9.86527 9.19947 9.76904 9.33901 9.63517 9.43484C8.81717 10.0188 8.50517 11.1938 9.14217 12.1098C9.94416 13.2632 10.9451 14.2645 12.0982 15.0668C13.0152 15.7028 14.1902 15.3908 14.7732 14.5738C14.8689 14.4396 15.0086 14.343 15.168 14.3008C15.3274 14.2586 15.4966 14.2735 15.6462 14.3428L15.8672 14.4448C16.9452 14.9448 18.0892 15.2868 19.2642 15.4608C19.7542 15.5338 20.1182 15.9548 20.1182 16.4508V19.0898C20.1182 19.2212 20.0923 19.3514 20.042 19.4728C19.9916 19.5941 19.9179 19.7044 19.8249 19.7973C19.732 19.8902 19.6216 19.9638 19.5002 20.014C19.3787 20.0642 19.2486 20.09 19.1172 20.0898H19.1152Z"
        fill="#504B49"
      />
    </svg>
  );
};

export default PhoneIcon;
