import styled from '@emotion/styled';

export const SegmentContainer: React.FC<ISegmentContainer> = ({
  children,
  title,
}) => {
  return (
    <OrderContainer>
      <StyledLabel>{title}</StyledLabel>
      {children}
    </OrderContainer>
  );
};

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  border-radius: 0.75rem;
  box-shadow: 0 0rem 0.8rem -0.2rem ${({ theme }) => theme.palette.black};
  /* border: 2px solid green; */

  & > * {
    padding: 1.5rem;
    /* border: 2px solid red; */
  }

  @media screen and (max-width: 540px) {
    & > * {
      padding: 1rem;
    }
  }
`;

const StyledLabel = styled.h1`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.black};
`;

interface ISegmentContainer {
  children: any;
  title: string;
}
