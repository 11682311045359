import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1182 21.0894C10.8551 20.0121 9.68436 18.831 8.61817 17.5584C7.01816 15.6474 5.11816 12.8014 5.11816 10.0894C5.11747 8.70441 5.52767 7.35029 6.29686 6.19847C7.06604 5.04665 8.15964 4.14891 9.43923 3.61885C10.7188 3.08878 12.1269 2.95024 13.4853 3.22074C14.8436 3.49124 16.0912 4.15863 17.0702 5.13845C17.7219 5.78731 18.2385 6.55898 18.5901 7.40882C18.9416 8.25866 19.1211 9.16978 19.1182 10.0894C19.1182 12.8014 17.2182 15.6474 15.6182 17.5584C14.552 18.831 13.3812 20.0121 12.1182 21.0894ZM12.1182 7.08945C11.3225 7.08945 10.5595 7.40552 9.99684 7.96813C9.43424 8.53074 9.11817 9.2938 9.11817 10.0894C9.11817 10.8851 9.43424 11.6482 9.99684 12.2108C10.5595 12.7734 11.3225 13.0894 12.1182 13.0894C12.9138 13.0894 13.6769 12.7734 14.2395 12.2108C14.8021 11.6482 15.1182 10.8851 15.1182 10.0894C15.1182 9.2938 14.8021 8.53074 14.2395 7.96813C13.6769 7.40552 12.9138 7.08945 12.1182 7.08945Z"
        fill="#504B49"
      />
    </svg>
  );
};

export default LocationIcon;
