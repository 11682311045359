import React from 'react';

import PointsCard from '../../../../components/Card/PointsCard';

const SolutionsWeOfferData = [
  {
    title: 'Speedy Deliveries',
    description:
      'In the dynamic worlds of fashion and beauty, Darum ensures your products reach customers swiftly, keeping you ahead of trends and delivering fresh styles and beauty products on time.',
  },
  {
    title: 'Secure Packaging',
    description:
      "We understand the importance of these delicate items. Our secure packaging keeps your fashion and beauty products in pristine condition during transit, protecting your brand's image.",
  },
  {
    title: 'Brand Image Enhancement',
    description:
      "Darum's professional delivery service aligns seamlessly with your brand's image, enhancing your reputation in the style and beauty industries.",
  },
];
const SolutionsWeOffer = () => {
  return (
    <PointsCard
      header="Solutions We Offer"
      src="/static/img/deliveryManAndWoman.png"
      data={SolutionsWeOfferData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 0rem"
      // className="reverse"
    />
  );
};

export default SolutionsWeOffer;
