import React from 'react';

import styled from '@emotion/styled';

import { HeroCard } from '../../../components/Card';
import FAQ from '../../../components/FAQ.tsx/FAQ';
// import HearFromBusinessLikeYours from '../../../components/HearFromBusinessLikeYours/HearFromBusinessLikeYours';
import Page from '../../../components/Page/Page';
import ProblemsWeSolve from './ProblemsWeSolve/ProblemsWeSolve';
import SolutionsWeOffer from './SolutionsWeOffer/SolutionsWeOffer';

const SMEPage = () => {
  return (
    <Page title="Darum | SME">
      <Container>
        <HeroCard
          src="/static/img/manWithBox.png"
          header="SME-Focused Delivery Solutions by Darum"
          description="Scalable and Budget-Friendly Logistics for Small and Medium-sized Enterprises"
          btnLink="https://business.darum.com.ng"
          btnText="Get Started"
        />
        <SolutionsWeOffer />
        <ProblemsWeSolve />
        {/* <HearFromBusinessLikeYours /> */}
        <FAQ />
      </Container>
    </Page>
  );
};

export default SMEPage;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 3.03rem;

  @media (max-width: 480px) {
    margin-top: 1.88rem;
  }
`;
