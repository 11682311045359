import React from 'react';

import styled from '@emotion/styled';

import FacebookIcon from '../../components/Icons/FacebookIcon';
import Instagram from '../../components/Icons/Instagram';
import TwitterIcon from '../../components/Icons/TwitterIcon';
import { ExternalLink, NavLink } from '../../components/Link/Link';
import { IFooterNavConfigListData } from './FooterNavConfig';

interface IFooterNavSegment {
  title: string;
  data: IFooterNavConfigListData[];
  showSocials?: boolean;
}
const FooterNavSegment: React.FC<IFooterNavSegment> = ({
  title,
  data,
  showSocials,
}) => {
  return (
    <Container className={!showSocials ? 'mobile' : ''}>
      <Title>{title}</Title>
      {data.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item.externalPath ? (
              <ExternalLink to={item.path} className="footer">
                {item?.icon} {item.label}{' '}
              </ExternalLink>
            ) : (
              <NavLink className={'footer'} to={item.path}>
                {item?.icon} {item.label}{' '}
              </NavLink>
            )}
          </React.Fragment>
        );
      })}
      {showSocials && (
        <SocialIcons>
          <NavLink to={'https://www.facebook.com/Darum.ng'}>
            <FacebookIcon />
          </NavLink>
          <NavLink to={'https://twitter.com/DarumNg'}>
            <TwitterIcon />
          </NavLink>
          <NavLink to={'https://www.instagram.com/darum.ng/'}>
            <Instagram />
          </NavLink>
        </SocialIcons>
      )}
    </Container>
  );
};

export default FooterNavSegment;

const Container = styled.div`
  /* border: 2px solid yellow; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: ${({ theme }) => theme.palette.secondaryTextColor};
  min-width: 9.4rem;

  &.mobile {
    @media (max-width: 480px) {
      display: none;
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.25rem;
`;

const SocialIcons = styled.section`
  display: flex;
  gap: 0.9rem;
`;
