import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Router from './routes/routes';

function App() {
  return (
    <>
      <ScrollToTop />
      <Router />
    </>
  );
}

export default App;
