export const OurServicesData = [
  {
    header: 'Last-Mile Delivery',
    description:
      "Experience a seamless last-mile delivery solution. We make sure your products are delivered quickly to your consumers' doorsteps, increasing their satisfaction and loyalty.",
    src: '/static/img/dispatchRider.png',
  },
  {
    header: 'Resource Sharing',
    description:
      'Darum supports teamwork. We provide resource-sharing options that streamline delivery processes, cut costs, and effectively utilize assets.',
    src: '/static/img/writter.png',
  },
  {
    header: 'Brand Visibility',
    description:
      'Elevate your Brand Visibility , We help you stand out in the market, connecting your brand with the right audience, and enhancing recognition & trust.',
    src: '/static/img/keke.png',
  },
  {
    header: 'Cost-Effective Models',
    description:
      'Maximise savings with our Cost-Effective Models. We offer innovative pricing structures to boost your profitability without sacrificing service quality',
    src: '/static/img/meetWithFriend.png',
  },
  {
    header: 'Technology Integration',
    description:
      'Darum leverages cutting-edge technology to enhance the efficiency and transparency of our delivery services. From real-time tracking to data-driven insights, we bring innovation to logistics.',
    src: '/static/img/holdPackage.png',
  },
  {
    header: 'Customer Support',
    description:
      'Experience dedicated and round-the-clock customer support to ensure smooth logistics operations.',
    src: '/static/img/holdBox.png',
  },
];
