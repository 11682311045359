export const SharingPolicyData = [
  {
    segmentName: 'Introduction',
    data: [
      'Please carefully read and understand this Policy (and any amendments made thereto from time to time by MASON ATLANTIC LIMITED (hereinafter referred to as “the Company”) for DARUM, before signing up.',
      'DARUM is a bike hailing application that renders the services of transportation of various kinds of goods and items that are legally allowed to be transported within Nigeria. The trade name DARUM is duly registered under the Laws of the Federal Republic of Nigeria.',
      'By signing up to partner with DARUM, you agree to be bound by this Profit Sharing Policy (hereinafter referred to as “the Policy”).',
      'If you do not agree with these terms as contained therein or any portion thereof, please do not sign up to work with us.',
      'You shall be deemed to have agreed and accepted the terms contained in this Policy once you click the “sign-up” button immediately below. Kindly note that you will not be able to work with us if you decline the terms as contained herein.',
    ],
  },
  {
    segmentName: 'Application',
    data: [
      'This Policy shall apply to every entity registered under the provisions of the Companies and Allied Matters Act 2020, and whose name appears on the Corporate Affairs Commission’s Register as a going concern, which includes: limited liability companies, limited partnerships, limited liability partnerships and business names, whose main or subsidiary objective cuts across carrying on the business of transportation and logistics, insofar, they are registered to work with us (hereinafter referred to as “the Partner” or “the Partners”).',
    ],
  },
  {
    segmentName: 'Scope',
    data: [
      'The Company and the Partners acknowledge that this Policy shall cover the contractual relationship between them as it relates to the signing-up of Partners and their riders and the further registration of their bikes on DARUM.',
      'The profit-sharing ratio that shall be provided for in this Policy Document shall cover each bike, that is, calculation of profits shall not be made per rider, but per bikes, as shown on DARUM for each job completed',
    ],
  },
];
