import React from 'react';

import PointsCard from '../../../../components/Card/PointsCard';

const ProblemsWeSolveData = [
  {
    title: 'Cold and Late Deliveries',
    description:
      'Restaurant food should be served hot and on time. We solve this problem by focusing on timely and temperature-controlled deliveries.',
  },
  {
    title: 'Customer Satisfaction',
    description:
      'Unreliable deliveries can lead to unhappy customers. Our services ensure your patrons receive their orders with satisfaction.',
  },
  {
    title: 'Reputation Management',
    description:
      "Restaurants rely on their reputation. Our hot and fresh food deliveries maintain and enhance your restaurant's image.",
  },
];
const ProblemsWeSolve = () => {
  return (
    <PointsCard
      header="Problems We Solve"
      // blondeHair
      src="/static/img/pizzaEating.png"
      data={ProblemsWeSolveData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 0rem"
      className="reverse"
    />
  );
};

export default ProblemsWeSolve;
