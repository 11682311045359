import React from 'react';

import styled from '@emotion/styled';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Header>Sharing Policy</Header>
      <UpperUl>
        {/* Another section start */}
        <ContentArea>
          <UpperUlChild>Introduction</UpperUlChild>
          <UnstyledLowerUl>
            <LowerUlChild>
              Darum is a bike hailing application that renders the services of
              transportation of various kinds of goods and items that are
              legally allowed to be transported within Nigeria. The trade name
              Darum is duly registered under the Laws of the Federal Republic of
              Nigeria. Due to the nature of the services rendered by Darum, we
              understand that you may have to disclose in confidence some
              information and personal data, and we hereby undertake that we are
              under an obligation to ensure we do not breach your confidence.
            </LowerUlChild>
          </UnstyledLowerUl>
        </ContentArea>
        {/* Another section start */}
        <ContentArea>
          <UpperUlChild>Scope</UpperUlChild>
          <TextPlace>
            The Privacy Policy highlights the personal data we collect, how it
            is used and shared, and your choices regarding this data. This
            Policy provides for how Darum collects and uses personal data. This
            Policy applies to all customers of our application, websites,
            features, or other services. In our scope, the customers of Darum
            covers from the companies or enterprise that registers its
            motorbikes on our application; the details of the motorbikes and the
            riders assigned to each motorbikes; the details of the customers,
            that is, the sender and receiver of an item to be delivered; and the
            details of the item(s) to be delivered.
          </TextPlace>
          <TextPlace>
            Furthermore, the data we collect and process are as follows:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              Name, phone number, e-mail address of sender and receiver of such
              items;
            </LowerUlChild>
            <LowerUlChild>
              Location of the sender and receiver of such item(s);
            </LowerUlChild>
            <LowerUlChild>
              The time and the destination of a delivery;
            </LowerUlChild>
            <LowerUlChild>
              The payment information of the parties to a delivery;
            </LowerUlChild>
            <LowerUlChild>
              The details of the item(s) to be delivered; and
            </LowerUlChild>
            <LowerUlChild>
              Identification data of the device on which Darum has been
              installed.
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            Darum processes the above captured data for the following purposes:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              Personal data collected and processed are for the purpose of
              connecting customers with riders to ease the issue of details of
              pick-up and delivery of item(s);
            </LowerUlChild>
            <LowerUlChild>
              The location and phone number details of customers is displayed to
              riders close to the pick-up location to enable efficient pick-up.
              Such data is collected only when the Darum application is
              activated. We may use such data to resolve quality issues related
              to our services;
            </LowerUlChild>
            <LowerUlChild>
              The data received of the routes taken by riders is to analyze the
              cost estimate for such services rendered;
            </LowerUlChild>
            <LowerUlChild>
              The personal details received from a customer will be used to
              communicate with you.
            </LowerUlChild>
            <LowerUlChild>
              The payment details received are used in processing a hassle free
              mode of payment for services rendered to a customer;
            </LowerUlChild>
            <LowerUlChild>
              The data are further used, where such instances arise, for
              customer support, research and development, to send marketing and
              non-marketing communications to customers and clients of the Darum
              application, to look into disputes between customers and the
              clients of the Darum application, either in personal capacity or
              through its riders, and for any legal proceedings.
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            The data we receive from other sources are as follows:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              The feedback of customers of the Darum application, such as,
              ratings, feedbacks, compliments and/or complaints;
            </LowerUlChild>
            <LowerUlChild>
              Customers of the Darum application participating in any of our
              referral programs;
            </LowerUlChild>
            <LowerUlChild>
              Vendors who help us verify identity of companies and enterprises
              including the riders associated to such companies and enterprises.
            </LowerUlChild>
            <LowerUlChild>
              Insurance details of motorbikes for riders; and
            </LowerUlChild>
            <LowerUlChild>Marketing service providers.</LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            Darum application does not sell, share or transfer customer personal
            data to third parties for their direct marketing, except with
            customers’ consent.
          </TextPlace>
        </ContentArea>
        {/* Another section start */}
        <ContentArea>
          <UpperUlChild>Cookies and third-party technologies</UpperUlChild>
          <TextPlace>
            A cookie is a small piece of data stored on the customer’s computer
            or any other devices by the web browser, applications while browsing
            a website or while such applications are in use. Cookies were
            designed to be a reliable mechanism for websites and applications to
            remember certain information or to record the customer’s activities.
          </TextPlace>
          <TextPlace>
            Darum uses cookies and similar technologies for purposes such as:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>Authenticating customers;</LowerUlChild>
            <LowerUlChild>
              Remembering customer preferences and settings;
            </LowerUlChild>
            <LowerUlChild>
              Determining the popularity of content; and
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            Delivering and measuring the effectiveness of advertising campaigns
          </TextPlace>
        </ContentArea>
        {/* Another section start */}
        <ContentArea>
          <UpperUlChild>Data and information sharing</UpperUlChild>
          <TextPlace>
            The nature of the services rendered by Darum requires that we share
            data and certain information with other customers or the
            company/enterprise at the request of these customers or the
            companies/enterprises.
          </TextPlace>
          <TextPlace>
            The data we may share with other customers are as follows:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              Customer’s (whose expression shall cover the sender and the
              receiver) first name, rating, and pickup and/or drop-off locations
              with the riders;
            </LowerUlChild>
            <LowerUlChild>
              For companies/enterprises and their riders, we may share data with
              the customer(s), including name and photo; make model, color,
              license plate, and photo of the motorbike; location (before and
              during trip); average rating provided by customers; total number
              of trips; length of use of the Darum application; contact
              information (depending upon applicable laws); and rider’s profile,
              including compliments and other feedback submitted by past
              customers; and
            </LowerUlChild>
            <LowerUlChild>
              We also provide customers with receipts containing information
              such as a breakdown of amounts charged, the first name of the
              rider, photo and route map.
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            The data we may share at the customer’s request are as follows:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              The receiver’s details and the rider’s location, at the request of
              the sender; and
            </LowerUlChild>
            <LowerUlChild>
              Darum business partners. For example, if a customer requests a
              service through a partnership or promotional offering made by a
              third party, Darum may share certain data with those third
              parties. This may include, for example, other services, platforms,
              apps, or websites that integrate with our APIs or other Darum
              business partners and their customers in connection with
              promotions, contests, or specialized services.
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            The data we may share with the general public are as follows:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              Darum may be obliged to share questions or comments from
              customers, companies or enterprises and their various riders,
              through public forums such as Darum websites and any of our social
              media pages. Such may be viewable by the public, including any
              personal data included in the questions or comments submitted by a
              customer.
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            The data we may share with the owner of Darum application on his/her
            phone:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              If a customer places an order for our services making use of the
              account of another customer, we shall share certain information,
              including real-time location data, with the owner of that account.
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            The data we may share with our subsidiaries and affiliates are as
            follows:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              We share data with our subsidiaries and affiliates to help us
              provide our services or conduct data processing on our behalf.
            </LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            The data we may share with companies registered under our platform:
            due to the nature of how our services are rendered, that is, through
            riders and motorbikes owned by companies registered on our platform,
            we tend to provider certain data to such companies or enterprises as
            the case may be, or consultants, research firms or business
            partners. The information data to be shared include but are not
            limited to:
          </TextPlace>
          <LowerUl>
            <LowerUlChild>
              We share data with our subsidiaries and affiliates to help us
              provide our services or conduct data processing on our behalf.
            </LowerUlChild>
            <LowerUlChild>Payment processors and facilitators;</LowerUlChild>
            <LowerUlChild>
              Background check and identity verification providers (drivers,
              delivery persons and cash riders only);
            </LowerUlChild>
            <LowerUlChild>Cloud storage providers;</LowerUlChild>
            <LowerUlChild>
              Google, in connection with the use of Google Maps in Darum’s
              application (see Google’s privacy policy for information on their
              collection and use of data);
            </LowerUlChild>
            <LowerUlChild>
              Marketing partners and marketing platform providers, including
              social media advertising services;
            </LowerUlChild>
            <LowerUlChild>Data analytics providers;</LowerUlChild>
            <LowerUlChild>
              Consultants, lawyers, accountants, and other professional service
              providers;
            </LowerUlChild>
            <LowerUlChild>Fleet partners; and</LowerUlChild>
            <LowerUlChild>Insurance and financing partners</LowerUlChild>
          </LowerUl>
          <TextPlace className="mt">
            Finally, Darum may share a customer’s personal data other than as
            described in this notice if we notify the customer and they give
            their consent that such information be shared.
          </TextPlace>
        </ContentArea>
        {/* Another section start */}
        <ContentArea>
          <UpperUlChild>Data Retention and deletion</UpperUlChild>
          <UnstyledLowerUl>
            <LowerUlChild>
              All data collected on Darum application is stored in the data box
              of the application, and only authorized personnel of Darum are
              granted access to the data only for the purpose of resolving
              issues associated with the use of the services.
            </LowerUlChild>
            <LowerUlChild>
              Data that involves location is processed in anonymity and shall
              only be personalized if the location data is needed to be linked
              to a rider for resolving disputes or fraud by any of the riders.
            </LowerUlChild>
            <LowerUlChild>
              In light of the above, Darum retains customer’s data for as long
              as necessary for the purposes described above. This means that we
              retain different categories of data for different periods of time
              depending on the category of customer to whom the data relates,
              the type of data, and the purposes for which we collected the
              data.
            </LowerUlChild>
            <LowerUlChild>
              Customers may, however, at any time, request deletion of their
              data that may have been stored by virtue of their use of the
              application, through the deletion of their account.
            </LowerUlChild>
            <LowerUlChild>
              Following an account deletion request, Darum deletes the
              customer’s account and data, unless they must be retained due to
              legal or regulatory requirements, for purposes of safety,
              security, and fraud prevention, or because of an issue relating to
              the customer’s account such as an outstanding credit or an
              unresolved claim or dispute. Because we are subject to legal and
              regulatory requirements relating to drivers and delivery persons,
              this generally means that we retain their account and data for a
              minimum of 7 years after a deletion request. For riders and
              receivers, their data is generally deleted within 90 days of a
              deletion request, except where retention is necessary for the
              above reasons.
            </LowerUlChild>
          </UnstyledLowerUl>
        </ContentArea>
        {/* Another section start */}
        <ContentArea>
          <UpperUlChild>Access and correction</UpperUlChild>
          <UnstyledLowerUl>
            <UnstyledLowerUl>
              You can access and update your personal data via the Darum app.
            </UnstyledLowerUl>
          </UnstyledLowerUl>
        </ContentArea>
        <ContentArea>
          <UpperUlChild>Governing law and disputes</UpperUlChild>
          <UnstyledLowerUl>
            <LowerUlChild>
              This Policy and every disputes that may arise from it shall be
              governed by, and construed in accordance with, the laws of the
              Federal Republic of Nigeria.
            </LowerUlChild>
          </UnstyledLowerUl>
        </ContentArea>
      </UpperUl>
    </Container>
  );
};

export default PrivacyPolicy;

const Container = styled.div`
  display: flex;
  margin-top: 4.44rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Header = styled.h1`
  color: ${({ theme }) => theme.palette.primaryTextColor};
  font-size: 3.5rem;
  font-weight: 800;

  @media (max-width: 480px) {
    font-size: 1.75rem;
    font-weight: 800;
    text-align: center;
  }
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const UpperUl = styled.ul`
  counter-reset: section;
  list-style: none;
  padding: 0rem;

  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const UpperUlChild = styled.li`
  color: #07071d;
  font-size: 1.75rem;
  font-weight: 600;

  &::before {
    content: counters(section, '.') '. ';
    counter-increment: section;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
    font-weight: 600;
  }
`;

const LowerUl = styled.ul`
  /* counter-reset: subsection; */
  list-style: disc;
  color: #4e4e65;
  /* padding: 1rem; */

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const LowerUlChild = styled.li``;

const UnstyledLowerUl = styled.ul`
  /* counter-reset: subsection; */
  list-style: none;
  color: #4e4e65;
  padding: 0rem;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const TextPlace = styled.div`
  color: #4e4e65;
  margin-bottom: 1rem;

  &.mt {
    margin-top: 2rem;
  }
`;
