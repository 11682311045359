import React from 'react';

import styled from '@emotion/styled';

import PrimaryButton from '../Button/PrimaryButton';
import Img from '../Img/Img';
import { ExternalLink } from '../Link/Link';

interface IBeatTheTraffic {
  title: string;
  description: string;
  link: string;
  buttonLabel: string;
}

const BeatTheTraffic: React.FC<IBeatTheTraffic> = ({
  title,
  description,
  link,
  buttonLabel,
}) => {
  return (
    <Container>
      <ImgWrapper>
        <Img src={'/static/img/map.png'} />
      </ImgWrapper>
      <TextArea>
        <TextAreaHeader>{title}</TextAreaHeader>
        <div>{description}</div>
        <ExternalLink to={link}>
          <PrimaryButton>{buttonLabel}</PrimaryButton>
        </ExternalLink>
      </TextArea>
    </Container>
  );
};

export default BeatTheTraffic;

const Container = styled.div`
  display: flex;
  padding: 2rem 5rem;
  gap: 1rem;
  align-items: center;
  /* border: 2px solid red; */
  background-color: #fafafa;
  margin-left: -5rem;
  margin-right: -5rem;

  @media (max-width: 480px) {
    flex-direction: column;
    flex-direction: column-reverse;
    gap: 2rem;
    margin-left: 0rem;
    margin-right: 0rem;
    padding: 0rem;
    margin-bottom: 7.61rem;
    background-color: white;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: left;
  &.reverse {
    grid-column: 1;
    grid-row: 1;
  }

  & > img {
    width: 100%;
  }
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TextAreaHeader = styled.h2`
  color: ${({ theme }) => theme.palette.primaryTextColor};
  font-size: 2rem;
`;
