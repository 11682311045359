import React from 'react';

import styled from '@emotion/styled';

import { SpinningIcon } from '../SpinningIcon/SpinningIcon';
import { IButton } from './interface';

const PrimaryButton: React.FC<IButton> = ({ children, className }) => {
  return (
    <StyledButton className={className ? className : ''}>
      {children}
    </StyledButton>
  );
};

export default PrimaryButton;

const StyledButton = styled.button`
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.palette.white};
  background: ${({ theme }) => theme.palette.primaryButtonColor};
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 0.88rem 2rem;
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
`;

interface IFormSubmitButton {
  iconColor?: string;
  iconSize?: string;
  showIcon?: boolean;
  children: string;
  width?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  // onClick?: (...args: any) => any;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const FormSubmitButton: React.FC<IFormSubmitButton> = ({
  iconColor,
  iconSize,
  showIcon,
  children,
  className,
  type,
  disabled,
  onClick,
  width,
}) => {
  return (
    <FormSubmitBtn
      type={type}
      disabled={disabled}
      className={className ? className : ''}
      onClick={onClick}
    >
      {children}
      {showIcon ? (
        <SpinningIcon color={iconColor || 'white'} size={Number(iconSize)} />
      ) : (
        ''
      )}
    </FormSubmitBtn>
  );
};

export const FormSubmitBtn = styled.button`
  background-color: ${({ theme }) => theme.palette.brandPrimary};
  padding: 0.88rem 2rem;
  font-weight: 700;
  font-size: 1rem;
  color: white;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;
  margin: 2rem auto;
  /* for the spinner */
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;
