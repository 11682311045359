import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import styled from '@emotion/styled';

import Footer from './Footer/Footer';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import Header from './Header/Header';

const LandingPageLayout = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <HamburgerMenu
        open={open}
        toggleNav={() => {
          setOpen(!open);
        }}
      />
      <Header
        open={open}
        toggleNav={() => {
          setOpen(!open);
        }}
      />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
};

export default LandingPageLayout;

const Main = styled.main`
  display: flex;
  /* border: 2px solid red; */
  margin: 0 5rem;

  @media (max-width: 480px) {
    margin: 0 1.65rem;
  }
`;
