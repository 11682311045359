import React from 'react';

import styled from '@emotion/styled';

import PrimaryButton from '../../components/Button/PrimaryButton';
import { FullTextLogo } from '../../components/CompanyLogo';
import { ExternalLink, NavLink } from '../../components/Link/Link';
import FooterNav from './FooterNav';

const Footer = () => {
  return (
    <Container>
      <LogoArea>
        <NavLink to="/">
          <FullTextLogo />
        </NavLink>
        <ExternalLink to="https://business.darum.com.ng">
          <PrimaryButton>Get Started</PrimaryButton>
        </ExternalLink>
      </LogoArea>

      <FooterNav />
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  /* border: 2px solid red; */
  display: flex;
  background: ${({ theme }) => theme.palette.brandBackdrop};
  padding: 3.88rem 4rem;
  gap: 10.56rem;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 4.58rem;
    padding: 2.5rem 2.5rem;
  }
`;

const LogoArea = styled.div`
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;
