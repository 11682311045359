export const WhyChooseUsData = [
  {
    header: 'Cost Savings',
    description:
      'Spend less on logistics and Maximise your money for other important business operations.',
    src: '/static/img/personWithNote.png',
  },
  {
    header: 'Professionalism',
    description:
      'Rely on a group of highly skilled professionals with vast logistics experience.',
    src: '/static/img/personWithPackage.png',
  },
  {
    header: 'Multi-Drop Capabilities',
    description:
      'Improve multi-stop routes for quicker and more affordable delivery. ',
    src: '/static/img/phoneWithMap.png',
  },
  {
    header: 'Rider Tracking',
    description:
      'Real-time tracking of your deliveries and rider locations assures transparency.',
    src: '/static/img/riderMan.png',
  },
];
