export const TermsAndPolicyData = [
  {
    segmentName: 'Acceptance of terms',
    data: [
      'Darum Application (hereinafter referred to as “Darum”, “we” or “us”) is an online bike hailing which renders mainly, services in the logistics sector amongst other related services. The services are further subject to the following Terms of Service (“T&C”). By registering on the Darum Application or the website (http://www.darum.ng), such user acknowledges to have read, understood and hereby agrees to be bound by the T&C accepts the T&C. For third party users, they hereby represent and warrant that such person or entity has the authority to bind such company, business or other legal entity and its affiliates to the T&C. If you do not have such authority, or if you do not agree with the T&C, you must not accept this T&C and may not proceed to register on the Darum Application.',
      'We reserve the right, at our sole discretion, to change or modify portions of this T&C at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these terms were last revised. We will also notify you, either through the services user interface, in an email notification or through other reasonable means. Any such changes will become effective no earlier than 12 (twelve) calendar days after they are posted, except for changes addressing new functions of the services or changes made for legal reasons will be effective immediately. Your continued use of the service after the date any such changes become effective constitutes your acceptance of the new T&C.',
      'You may be required to register with Darum Application in order to access and use certain features of the Service. If you choose to register, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the registration form. You acknowledge and agree that your use of certain features of the Application requires current and accurate information about you, including your address and mobile phone number. Darum Application is not responsible for any delays or inabilities to use our services that result from your providing false, inaccurate, incomplete or out-of-date information. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 18 years old, you may use our service, by registering, only with the approval of your parent or guardian.',
      'Every user is responsible for maintaining the confidentiality of their password and account, if any, and are fully responsible for any and all activities that occur under such password or account. You agree to (a) immediately notify Darum Application of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the service. Darum Application will not be liable for any loss or damage arising from your failure to act in accordance with the T&C.',
      'In addition, when using certain services, you will be subject to any additional terms applicable to such services that may be posted on the Darum Application from time to time, including, without limitation, the Privacy Policy located at https://darum.com.ng/privacy-policy and the FAQ Guide, which describes in detail how you may use the Darum Services. All such terms are hereby incorporated by reference into this T&C.',
    ],
  },
  {
    segmentName: 'Description of service',
    data: [
      'Darum is a bike hailing application that does not directly provide delivery and moving services and the Company is not a transportation and delivery provider. It is up to our logistics partners (“the Service Providers”) you are registered to, to accept your delivery requests and it is up to you (references to “You”, “Your” or “User” shall mean references to each visitor to the Website or the Application (as defined below), as the context requires) to accept their quotes/rates for your delivery (the “Service Provider”). The service Darum renders is to link you with your Service Provider(s) (“the Logistics Partners”) through the use of an application supplied by Darum and downloaded and installed by you on your single mobile or tablet device (the “Application”) or Web application but does not nor is it intended to provide delivery services or any act that can be construed in any way as an act of a Delivery Provider. The Company is not responsible or liable for the acts and/or omissions of any Delivery Provider and/or any delivery or shipping cargo provided to you',
    ],
  },
  {
    segmentName: 'Mobile services',
    data: [
      'The Service includes certain services that are available via a mobile device, including (i) the ability to upload content to the Service via a mobile device; (ii) the ability to browse the Service from a mobile device; and (iii) the ability to access certain features through an application downloaded and installed on a mobile device (collectively, the “Mobile Services”). To the extent you access the Service through a mobile device, your wireless service carrier’s standard charges, data rates and other fees may apply. In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by your carrier, and not all Mobile Services may work with all carriers or devices. By using the Mobile Services, you agree that we may communicate with you regarding Darum and other entities by Short Message Services, phone call or other electronic means to your mobile device and that certain information about your usage of the Mobile Services may be communicated to us. You agree that as part of the registration process, Darum may request that you verify your mobile device via Short Message Services. In the event you change or deactivate your mobile telephone number, you agree to promptly update your account information with us to ensure that your messages are not sent to the person that acquires your old number.',
    ],
  },
  {
    segmentName: 'General conditions / use of the service',
    data: [
      'Subject to this T&C, you may access and use the Service only for lawful purposes. All rights, title and interest in and to the Service and its components will remain with and belong exclusively to Darum. You shall not (a) sub license, resell, rent, lease, transfer, assign, time share or otherwise make the Service available to any third party, except as set forth in clause 1.2; (b) use the Service in any unlawful manner (including without limitation in violation of any data, privacy or export control laws) or in any manner that interferes with or disrupts the integrity or performance of the Service or its components or otherwise violates our acceptable use of policy (as defined below); or (c) modify, adapt or hack the Service to, or otherwise attempt to gain unauthorized access to the Service or its related systems or networks. You shall act in accordance with with any codes of conduct, policies or other notices Darum provides you or publishes in connection with the Service, and you shall promptly notify Darum if you learn of a security breach related to the Service. We shall not have any liability or responsibility for the actions of any third party carrier that may provide shipping services for us in connection with the Service.',
      'By voluntarily providing us with personal information, you hereby represent that you are the owner of such personal information or are otherwise authorized to provide it to your Service Provider through Darum. You further agree that you are consenting to our collection, use, storage and/or disclosure of such personal information in accordance with our Privacy Policy. You acknowledge and agree that your communications with Darum will inevitably result in the transfer of information, including personal information, across international boundaries. If you provide personal information to your Service provider through Darum, you acknowledge and agree that such personal information may be transferred from your current location to the offices and servers of Darum and the authorized third parties referred to herein, located in Nigeria, United States of America and other countries (the “other Countries”) for storage and processing in accordance with this Policy. You acknowledge and agree that the privacy and data security laws of such other Countries may be different from the privacy and data security laws in force in the country in which you reside and agree that the privacy and security laws in effect in the applicable other Countries may govern how your personal information may be collected, used, stored and/or disclosed.',
      'Any software that may be made available by Darum in connection with the Service, including without limitation the plug-ins, (“Software”) contains proprietary and confidential information that is protected by applicable intellectual property and other laws. Subject to the T&C herein provided, Darum hereby grants you a non-transferable, non-sub licensable and non-exclusive right and license to use the object code of any Software solely in connection with the Service, provided that you shall not (and shall not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code or sell, assign, sublicense or otherwise transfer any right in any Software. You agree not to access the Service by any means other than through the interface that is provided by Darum for use in accessing the Service. Any rights not expressly granted herein are reserved and no license or right to use any trademark of Darum or any third party is granted to you in connection with the Service.',
    ],
  },
  {
    segmentName: 'Prohibited shipments',
    data: [
      'You may not tender for shipment any items prohibited by law as detailed by our courier partners or any of our other carrier partners.',
      'We or your Service Provider reserve the right, but have no obligation, to open and inspect your shipment at any time and may permit and/or contact government authorities to carry out such inspections and seize shipments as they may consider appropriate. We may also photograph items in your shipment for our internal use in order to provide the Services. We reserve the right to reject or suspend the carriage of any prohibited items or any shipment that contains materials that may damage other shipments or that may constitute a risk to our equipment or employees or to those of our service providers. We may or may not notify you of any of the foregoing and we are not responsible for and hereby disclaim any liability relating to any non-delivery of any items that are prohibited by this T&C or by law and any such items may be turned over to authorities, discarded, or returned to the sender (in each case in Service providers sole discretion). You may request that your Service Provider and its agents or third-party business partners not open, remove packaging, or otherwise inspect your Pre-Packaged Shipment (defined herein). By doing so, you waive any right to reimbursement for loss or damage to your shipment, as further specified in this T&C. However, you acknowledge and agree that your service provider (and its agents or third-party business partners) may take such actions with respect to your shipment, even if you request otherwise, if your Service Provider determines in its sole discretion that such action is necessary to assess compliance with this T&C or is otherwise required by applicable law or regulation.',
    ],
  },
  {
    segmentName: 'Payment and charges',
    data: [
      'The use of the mobile application and web application and the Service is free of charge according to your Service Provider’s level of service; you will be required to provide Ship information and fund the means of payment (usually debit cards or e-wallet) acceptable to Darum. When you arrange for shipment, all charges for the shipment and any additional fees payable to your service provider (“Charges”) will be charged to the debit card, ewallet or other payment instrument associated with your account. You hereby authorize Darum to bill your payment instrument for Charges in accordance with these Terms. Except as otherwise agreed by the parties, all charges, fees, or surcharges shall be those in effect at the time of shipping, available either via email, on the Site or in-apps. The applicable Charges will be based upon the characteristics of the shipment actually tendered to your service provider or rates agreed upon. If you dispute any Charges you must let Darum or the Service Provider know within thirty days. Darum does not have the right to add or change or adjust rates specified and agreed between customer and service provider.',
      'Shippers are responsible for providing accurate and complete shipment information to service provider, including service selected, number, weight, and dimensions of shipments. If any aspect of the shipment information is incomplete or incorrect as determined by Service Provider/Logistic Partner’s in its sole discretion, service provider may adjust Charges at any time if stated on their T&C.',
      'Once a delivery method and starting point/end point has been entered into the mobile application or the web application, it will provide Users with the quote from your service provider only, thus giving the right to our users to accept and reject each quote. By accepting a quote, you accept to pay the Delivery Price quoted inclusive of VAT/surcharges or any other charges.',
    ],
  },
  {
    segmentName: 'Indemnity',
    data: [
      'By accepting the User Terms and using the Service and the Application, the User shall indemnify the Company and keep the Company and Service Provider indemnified against all demands, claims, action, proceedings, costs, charges or expenses including but not limited to penalties, storage charges, retrieval and administrative costs, duties and taxes incurred, suffered or sustained by us in connection with the Service we have provided. Read further on our Indemnity Policy.',
    ],
  },
  {
    segmentName: 'Responsibility for loss or damage',
    data: [
      'If a Shipment is lost or damaged while in the possession of the Service Provider, you will need to contact Darum who will then contact the Service Provider whom may or may not provide insurance under Darum platform.',
    ],
  },
  {
    segmentName: 'Referrals',
    data: [
      'From time to time, Darum platform allows Service Provider to add referrals in the Darum Application, may offer a referral program which enables you to earn credits redeemable for shipping services by sharing a unique referral link provided to you by your service provider through Darum platform(“Referral Link”) with your friends and family.',
    ],
  },
  {
    segmentName: 'Application law and jurisdiction',
    data: [
      'This T&C will in all respects be governed by and construed under the laws of the Federal Republic of Nigeria.',
      'The Parties hereby consent and submit to the exclusive jurisdiction of courts (depending which country you are in) in any dispute arising from or in connection with this T&C.',
      'We may update the User Terms from time to time which will apply to the use of the Company site at via www.darum.com.ng and such changes shall be binding on the User upon posting.',
      'We reserve the right to revise the User Terms at any time without prior notice.',
    ],
  },
];
