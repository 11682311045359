export const IndemityPolicyData = [
  {
    segmentName: 'Introduction',
    data: [
      'Darum is a Registered Trademark owned by Mason Atlantic Limited.',
      'The Trademark is duly registered under two classes. It is majorly registered under Class 42 (Scientific and technological services and research and design relating thereto; industrial analysis and research services; design and development of computer hardware and software) and further registered under Class 39 (Transport; packaging and storage of goods; travel arrangement; other services).',
      'By virtue of the above, the main objective of the Darum is to act as an online platform (“the Darum Application”) for bike hailing for the purpose of transporting and carrying goods, gift items and documents which are not banned under the Nigeria Laws. The Darum Application is the social platform where companies and/or enterprises register their motorbikes and their riders respectively, for the purpose of having users/customers order and book a bike to transport or carry their goods within Nigeria.',
      'In light of the above, it is pertinent that the Darum Application provides clearly that it does not represent the companies and/or enterprises or the riders registered on its platform.',
      'We undertake as our obligation to carry out a due diligence exercise on companies and/or enterprises alongside their riders, and be certain that these companies and/or enterprises registered on our platform are in compliance with every relevant regulations, and have a valid insurance indemnity policy, we, however, cannot warrant any form of relationship between us and the riders registered to every motorbike on our platform.',
    ],
  },
  {
    segmentName: 'Indemnity Clause',
    data: [
      'The person or persons who use the social platform, whether the senders of a good or gift item or the receivers of same (hereinafter referred to as “the Indemnifying Parties”) hereby jointly and severally covenant to the owner of the Darum Application (hereinafter referred to as “the Indemnified Party”) against all claims, costs, losses, damages and interest that they may suffer or incur by reason of any breach of any warranties, covenants ad undertakings with respect to the representations or warranties made on behalf of or in respect to the Indemnified Party by the Indemnifying Parties jointly or severally in respect to the main objective of the Indemnified Party;',
      'The Indemnifying Parties hereby further indemnify and keep the Indemnified Party indemnified and all persons claiming through or under it against all demands, claims, liabilities, losses, costs, charges and expenses whatsoever (including legal costs and other costs, charges and expenses) that the Indemnified Party or any of its lawful and authorised representatives may incur in connection with the role of middle man being played between the companies and/or enterprises registered on the Darum Application, alongside its riders and the Indemnifying Parties;',
      'The Indemnifying Parties hereby further indemnify and keep the Indemnified Party indemnified and all persons claiming through or under it against all demands, claims, liabilities, losses, costs, charges and expenses whatsoever (including legal costs and other costs, charges and expenses) that the Indemnified Party or any of its lawful and authorised representatives may incur in connection with any other related roles being played between the companies and/or enterprises registered on the Darum Application, alongside its riders and the Indemnifying Parties;',
      'The Indemnifying Parties’ obligations to indemnify the Indemnified Party shall also cover any forms of demands, claims, liabilities, losses, costs, expenses, charge, interruptions and disturbances that may have or may not have reasonably be expected to give rise to the Indemnified Party’s right of indemnification;',
      'The Indemnifying Parties shall make no claim against the Indemnified Party to recover any damage or loss which the Indemnifying Parties or any other person(s) may suffer in connection with the use of the Darum Application. However, the Indemnified Party hereby undertake to ensure that all damages or losses that may have been incurred during the use of the Darum Application shall be well compensated for by the companies and/or enterprises that registered such rider or riders and motorbike or motorbikes that may have negligently contributed to such damages or losses as the case may warrant; and',
      'The Indemnifying Parties agree to indemnify, defend, and hold harmless the Indemnified Party, its officials, agents, employees, and representatives, from and against any and all claims, demands, defense costs and liabilities of any kind or nature arising out of or in connection with the Indemnifying Parties’ commission or omission and/or the failure in performance of the riders of the companies/riders registered on the Darum Application, or poor services provided by or on behalf of the riders of the companies/riders registered on the Darum Application; except for those claims and demands which arise out of the sole negligence or willful misconduct of the Indemnified Party.',
    ],
  },
];
