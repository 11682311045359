import React from 'react';

import PointsCard from '../../../../components/Card/PointsCard';

const ProblemsWeSolveData = [
  {
    title: 'Cart Abandonment',
    description:
      'High shipping costs and slow deliveries lead to cart abandonment. We solve this by providing swift, cost-effective, and transparent delivery services.',
  },
  {
    title: 'Delivery Uncertainty',
    description:
      'Customers want to know where their packages are. Our real-time tracking solves this problem, keeping customers informed every step of the way.',
  },
  {
    title: 'High Delivery Costs',
    description:
      'E-commerce businesses often face high last-mile delivery costs. Our cost optimization strategies help reduce these expenses while maintaining service quality.',
  },
];
const ProblemsWeSolve = () => {
  return (
    <PointsCard
      header="Problems We Solve"
      // blondeHair
      src="/static/img/womanOnDread.png"
      data={ProblemsWeSolveData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 0rem"
      className="reverse"
    />
  );
};

export default ProblemsWeSolve;
