import React from 'react';

import styled from '@emotion/styled';

import { HoverDropDown } from '../../components/DropDown/DropDown';
import DropDownCell from '../../components/DropDown/DropDownCell';
import { DownCheveron } from '../../components/Icons/DownCheveron';
import { NavLink } from '../../components/Link/Link';
import { CONTACT_US } from '../../routes/routeConstants';
import { NavDropDownData } from '../NavData';

const Nav = () => {
  return (
    <Container>
      <HoverDropDown
        label={
          <>
            Products & Services
            <DownCheveron />
          </>
        }
      >
        <DropDownContent>
          {NavDropDownData['Products & Services'].map((data, index) => (
            <NavLink to={data.link} key={index}>
              <DropDownCell
                src={data.src}
                title={data.title}
                description={data.description}
                comingSoon={data.comingSoon}
              />
              {/* <DropDownContentCell>{data.title}</DropDownContentCell> */}
            </NavLink>
          ))}
        </DropDownContent>
      </HoverDropDown>

      <HoverDropDown
        label={
          <>
            Industries
            <DownCheveron />
          </>
        }
      >
        <DropDownContent>
          {NavDropDownData.Industries.map((data, index) => (
            <NavLink to={data.link} key={index}>
              <DropDownCell
                src={data.src}
                title={data.title}
                description={data.description}
                comingSoon={data.comingSoon}
              />
            </NavLink>
          ))}
        </DropDownContent>
      </HoverDropDown>
      {/* <NavLink to={ABOUT_US}>AboutUs</NavLink> */}
      {/* <NavLink to={BLOG}>Blog</NavLink> */}
      <NavLink to={CONTACT_US}>ContactUs</NavLink>
    </Container>
  );
};

export default Nav;

const Container = styled.nav`
  /* border: 2px solid green; */
  display: flex;
  align-items: center;
  gap: 2.5rem;

  @media (max-width: 480px) {
    display: None;
  }
`;

const DropDownContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;
  gap: 3rem;
`;
