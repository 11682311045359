import React from 'react';

import styled from '@emotion/styled';

import HStackCard from '../Card/HStackCard';

interface IData {
  header: string;
  description: string;
  src: string;
}
interface IHorizontalScrollView {
  data: IData[];
}

const HorizontalScrollView: React.FC<IHorizontalScrollView> = ({ data }) => {
  return (
    <Container>
      {data.map((data, item) => {
        return (
          <HStackCard
            key={item}
            header={data.header}
            description={data.description}
            src={data.src}
            className="horizontalScrollView"
          />
        );
      })}
    </Container>
  );
};

export default HorizontalScrollView;

const Container = styled.div`
  /* outline: 2px solid green; */
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 26%;
  gap: 1.5rem;
  height: fit-content;
  width: 100%;
  width: 90vw;
  margin-right: -7.5rem;
  padding-right: 4.5rem;

  @media (max-width: 480px) {
    grid-auto-columns: 98%;
    margin-right: -1.8rem;
    padding-right: 1.8rem;
  }

  /* Overflow */
  overflow-x: auto;
  overscroll-behavior-inline: contain;

  scroll-snap-type: inline mandatory;

  & > * {
    scroll-snap-align: start;
  }

  &::-webkit-scrollbar {
    width: 0rem;
  }
`;
