import React from 'react';

import styled from '@emotion/styled';

import Img from '../Img/Img';

interface Idata {
  title: string;
  description: string;
}

interface IPointsCard {
  header: string;
  src: string;
  className?: string;
  data: Idata[];
  margin?: string;
  margin_sm?: string;
}
const PointsCard: React.FC<IPointsCard> = ({
  header,
  src,
  className,
  data,
  margin,
  margin_sm,
}) => {
  return (
    <Container className={className} margin={margin} margin_sm={margin_sm}>
      <CardTextLabel>{header}</CardTextLabel>
      <ContentContainer className={className === 'reverse' ? className : ''}>
        <ImgWrapper className={className === 'reverse' ? className : ''}>
          <Img src={src} />
        </ImgWrapper>
        <TextContainer className={className === 'reverse' ? className : ''}>
          {data.map((item, index) => (
            <TextCell key={index}>
              <Header>{item.title}</Header>
              <Description>{item.description}</Description>
            </TextCell>
          ))}
        </TextContainer>
      </ContentContainer>
    </Container>
  );
};

export default PointsCard;

interface IContainer {
  margin?: string;
  margin_sm?: string;
}

const Container = styled.section<IContainer>`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.pointCardColor};

  margin: ${({ margin }) => (margin ? margin : '0')};

  @media (max-width: 480px) {
    gap: 1.5rem;
    margin: ${({ margin_sm }) => (margin_sm ? margin_sm : '0')};
  }
`;

const CardTextLabel = styled.h2`
  font-size: 3rem;
  font-weight: 700;

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 3.65rem;

  @media (max-width: 480px) {
    display: flex;
    gap: 1.5rem;
    flex-direction: column-reverse;
  }

  &.reverse {
    @media (max-width: 480px) {
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;

  &.reverse {
    grid-row: 1;
    grid-column: 1;
  }
`;

const TextCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const Header = styled.h3`
  font-size: 1.75rem;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4e4e65;

  &.horizontalScrollView {
    font-size: 0.875rem;
    color: #343538;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & > img {
    width: 100%;
  }

  &.reverse {
    grid-row: 1;
    grid-column: 2;
  }
`;
