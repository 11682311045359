import React, { useEffect } from 'react';

import styled from '@emotion/styled';

interface IInfiniteScrolling {
  title?: string;
}

const InfiniteScrolling: React.FC<IInfiniteScrolling> = ({ title }) => {
  useEffect(() => {
    const scrollers = document.querySelectorAll('.scroller');

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute('data-animated', 'true');
      });
    }
  }, []);
  return (
    <Container>
      <Title>{title}</Title>
      <Scroller className="scroller">
        <TagList className="scroller__inner">
          <Li>
            <Image src="static/img/foodCourt.png" />
          </Li>
          <Li>
            <Image src="static/img/ileIyan.png" />
          </Li>
          <Li>
            <Image src="static/img/evercare.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/chowdeck.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/myfoodbyhilda.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/shipbubble.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/terminalAfrica.png"></Image>
          </Li>
          {/*  */}
          <Li>
            <Image src="static/img/foodCourt.png" />
          </Li>
          <Li>
            <Image src="static/img/ileIyan.png" />
          </Li>
          <Li>
            <Image src="static/img/evercare.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/chowdeck.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/myfoodbyhilda.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/shipbubble.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/terminalAfrica.png"></Image>
          </Li>
          {/*  */}
          <Li>
            <Image src="static/img/foodCourt.png" />
          </Li>
          <Li>
            <Image src="static/img/ileIyan.png" />
          </Li>
          <Li>
            <Image src="static/img/evercare.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/chowdeck.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/myfoodbyhilda.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/shipbubble.png"></Image>
          </Li>
          <Li>
            <Image src="static/img/terminalAfrica.png"></Image>
          </Li>
        </TagList>
      </Scroller>
    </Container>
  );
};

export default InfiniteScrolling;

const Image = styled.img`
  @media (max-width: 480px) {
    height: 30px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 9.38rem;

  @media (max-width: 480px) {
    width: 90vw;
    margin-top: 5.38rem;
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.primaryTextColor};
  font-size: 3rem;
  font-weight: 600;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

const Scroller = styled.div`
  &.scroller {
    max-width: 100%;
  }

  &.scroller[data-animated='true'] {
    overflow: hidden;
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
  }

  &.scroller[data-animated='true'] .scroller__inner {
    width: fit-content;
    flex-wrap: nowrap;
    animation: scroll 30s linear infinite;
  }

  @keyframes scroll {
    to {
      transform: translate(-147.5%);
    }
  }
`;

const TagList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  &.scroller__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
  }
`;

const Li = styled.div`
  flex-shrink: 0;
  padding: 1rem;

  box-shadow: 0;
  width: fit-content;
`;
