import React, { useState } from 'react';

import styled from '@emotion/styled';

import { DownCheveron } from '../Icons/DownCheveron';
import UpCheveron from '../Icons/UpCheveron';
import { NavLink } from '../Link/Link';

export interface INavConfigListData {
  title: string;
  link: string;
  src?: string;
  description: string;
  comingSoon: boolean;
}

interface IHeaderCollapseableSegment {
  title: string;
  data: INavConfigListData[];
  className?: string;
  onClick: (...args: any) => void;
}

const HeaderCollapseableSegment: React.FC<IHeaderCollapseableSegment> = ({
  title,
  data,
  className,
  onClick,
}) => {
  const [showContent, setShowContent] = useState(false);

  const handleShowContent = () => {
    setShowContent(!showContent);
  };

  return (
    <Container>
      <TitleArea onClick={() => handleShowContent()}>
        <Title
          className={className === 'hamburger-menu' ? 'hamburger-menu' : ''}
        >
          {title}
        </Title>
        {showContent ? <UpCheveron /> : <DownCheveron />}
      </TitleArea>
      <RevealContent className={showContent ? 'reveal' : ''}>
        {data.map((item, index) => {
          return (
            <NavLink key={index} to={item.link} onClick={onClick}>
              <SubLabelWrapper>
                <SubLabel>{item.title}</SubLabel>
                <SubLabelDescription>{item.description}</SubLabelDescription>
                {item.comingSoon && <DropDownBadge>Coming Soon</DropDownBadge>}
              </SubLabelWrapper>
            </NavLink>
          );
        })}
      </RevealContent>
    </Container>
  );
};

export default HeaderCollapseableSegment;

const Container = styled.div`
  display: None;
  flex-direction: column;
  gap: 1rem;
  color: ${({ theme }) => theme.palette.secondaryTextColor};
  min-width: 9.4rem;

  @media (max-width: 480px) {
    display: flex;
  }
`;

const SubLabelWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin-left: 2rem;
`;

const SubLabel = styled.div`
  color: #504b49;
  font-size: 0.875rem;
  font-weight: 500;
`;

const SubLabelDescription = styled.div`
  color: #71748c;
  font-size: 0.875rem;
  font-weight: 400;
`;

const TitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: black;
`;

const RevealContent = styled.div`
  display: none;
  flex-direction: column;
  gap: 1.5rem;
  height: 0;
  transition: all 0.2s ease;

  &.reveal {
    height: fit-content;
    display: flex;
  }
`;

const DropDownBadge = styled.div`
  color: #139ab7;
  font-size: 0.75rem;
  font-weight: 400;
  background: #f1fbfe;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  width: fit-content;
`;
