import React from 'react';

import PointsCard from '../../../../components/Card/PointsCard';

const SolutionsWeOfferData = [
  {
    title: 'Scalable Deliveries',
    description:
      'Grow your business without worrying about delivery logistics. We scale our services to match your needs.',
  },
  {
    title: 'Budget-Friendly Models',
    description:
      "Our cost-effective models make sure you don't overspend on deliveries while maintaining quality.",
  },
  {
    title: 'Professional Couriers',
    description:
      'Your parcels are in good hands with our professional couriers, ensuring on-time and safe deliveries.',
  },
];

const SolutionsWeOffer = () => {
  return (
    <PointsCard
      header="Solutions We Offer"
      src="/static/img/personFoldingArms.png"
      data={SolutionsWeOfferData}
      margin="4.7rem 0rem"
      margin_sm="4.7rem 0rem 0rem"
      // className="reverse"
    />
  );
};

export default SolutionsWeOffer;
