import { useField } from 'formik';
import React from 'react';

import styled from '@emotion/styled';

import { DownCheveron } from '../Icons/DownCheveron';

export interface IInputField {
  label?: any;
  id?: string;
  name: string;
  type: string;
  placeholder?: string;
  rpiSrc?: string;
  lpiSrc?: string;
  children?: any;
  className?: string;
}

export const SelectField: React.FC<
  Omit<IInputField, 'placeholder' | 'type'>
> = ({ label, className, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <Select {...field} {...props} />
        <SelectDropDownIcon>
          <DownCheveron />
        </SelectDropDownIcon>
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};

export const TextInputField: React.FC<IInputField> = ({
  label,
  lpiSrc,
  rpiSrc,
  className,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <Input {...field} {...props} />
        {lpiSrc ? <LeftPlaceHolderCardIcon alt="icon" src={lpiSrc} /> : null}
        {rpiSrc ? <RightPlaceHolderCardIcon alt="icon" src={rpiSrc} /> : null}
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};

export const TextAreaInputField: React.FC<IInputField> = ({
  label,
  lpiSrc,
  rpiSrc,
  className,
  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <InputWrapper className={className}>
      <InputLabel htmlFor={props.name || props.id}>{label}</InputLabel>
      <InputContainer>
        <TextArea {...field} {...props} />
        {lpiSrc ? <LeftPlaceHolderCardIcon alt="icon" src={lpiSrc} /> : null}
        {rpiSrc ? <RightPlaceHolderCardIcon alt="icon" src={rpiSrc} /> : null}
      </InputContainer>
      {meta.touched && meta.error ? (
        <FieldErrorInfo>{meta.error}</FieldErrorInfo>
      ) : null}
    </InputWrapper>
  );
};

export const FieldErrorInfo = styled.div`
  font-size: 0.8rem;
  color: red;
`;

export const InputWrapper = styled.div`
  /* border: 1px solid red; */
  /* gap: 0.5rem; */
  /* padding: 4px; */
`;

export const InputLabel = styled.label`
  font-weight: 400;
`;

interface IInput {
  textAlign?: string;
  lpiSrc?: string;
  rpiSrc?: boolean;
}

export const Input = styled.input<IInput>`
  border: 1px solid ${({ theme }) => theme.palette.borderColor};
  /* border: 2px solid green; */
  border-radius: 4px;
  padding: ${({ rpiSrc }) => (rpiSrc ? '1rem 3rem 1rem 1rem' : '1rem')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondaryTextColor};
  font-size: 1rem;
  height: 48px;
  cursor: text;
  /* appearance: none; */

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.borderColor};
  }
  &:hover {
    cursor: text;
  }

  &::placeholder {
    color: #dad8d8;
    opacity: 1;
    font-weight: 400;
  }
`;

const TextArea = styled.textarea<IInput>`
  /* border: 2px solid red; */
  border: 1px solid ${({ theme }) => theme.palette.borderColor};
  border-radius: 4px;
  padding: ${({ lpiSrc, rpiSrc }) => (lpiSrc || rpiSrc ? '5rem' : '1rem')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.secondaryTextColor};
  font-size: 1rem;
  resize: none;
  cursor: text;
  /* appearance: none; */

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.borderColor};
  }
  &:hover {
    cursor: text;
  }

  &::placeholder {
    color: #dad8d8;
    opacity: 1;
  }
`;

export const Select = styled.select`
  border: 1px solid ${({ theme }) => theme.palette.borderColor};
  border-radius: 4px;
  padding: 0rem 1rem 0rem 1rem;
  font-weight: 400;
  font-size: 1rem;
  height: 48px;
  color: ${({ theme }) => theme.palette.secondaryTextColor};

  appearance: none;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.palette.borderColor};
  }
  &:hover {
    cursor: pointer;
  }

  &:has(option:checked:disabled) {
    color: #dad8d8;
  }
`;

export const InputContainer = styled.div`
  display: grid;
  height: fit-content;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const LeftPlaceHolderCardIcon = styled.img`
  height: 1.5rem;
  display: grid;
  margin-left: 0.8rem;
  margin-top: 0.8rem;
`;

export const RightPlaceHolderCardIcon = styled.img`
  height: 1.5rem;
  display: grid;
  margin-right: 1rem;
  margin-left: auto;
  margin-top: 0.8rem;
`;

export const SelectDropDownIcon = styled.div`
  height: 1.5rem;
  display: grid;
  margin-right: 1rem;
  margin-left: auto;
  margin-top: 0.8rem;
`;
