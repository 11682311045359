import React from 'react';

import styled from '@emotion/styled';

interface IFullTextLogo {
  className?: string;
}
const FullTextLogo: React.FC<IFullTextLogo> = ({ className }) => {
  return (
    <Container className={className === 'header' ? className : ''}>
      <img src="/static/svg/companyLogo.svg" alt="Company" />
    </Container>
  );
};

export default FullTextLogo;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.83231rem;

  &.header {
    width: 6.55488rem;
  }
`;
