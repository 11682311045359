import React from 'react';

import styled from '@emotion/styled';

import HStackCard from '../../../components/Card/HStackCard';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import { OurServicesData } from './OurServicesData';

const OurServices = () => {
  return (
    <SectionWrapper
      title="Our Services"
      margin="6.88rem 0rem"
      margin_sm="5.47rem 0rem"
    >
      <GridWrapper>
        {OurServicesData.map((data, item) => {
          return (
            <HStackCard
              key={item}
              header={data.header}
              description={data.description}
              src={data.src}
            />
          );
        })}
      </GridWrapper>
    </SectionWrapper>
  );
};

export default OurServices;

// const
const GridWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
