import React from 'react';

import styled from '@emotion/styled';

import PrimaryButton from '../../../components/Button/PrimaryButton';
import HorizontalScrollView from '../../../components/HorizontalScrollView/HorizontalScrollView';
import { ExternalLink } from '../../../components/Link/Link';
import SectionWrapper from '../../../components/SectionWrapper/SectionWrapper';
import { WhyChooseUsData } from './WhyChooseUsData';

const WhyChooseUs = () => {
  return (
    <SectionWrapper title="Why choose us">
      <Container>
        <HorizontalScrollView data={WhyChooseUsData} />
        <ExternalLink to="https://business.darum.com.ng">
          <PrimaryButton>Get Started</PrimaryButton>
        </ExternalLink>
      </Container>
    </SectionWrapper>
  );
};

export default WhyChooseUs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
`;
