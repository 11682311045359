export const HOME = '/';
export const PRODUCTS_AND_SERVICES = 'products-and-service';
export const INDUSTRIES = 'industries';
export const ABOUT_US = 'about-us';
export const BLOG = 'blog';
export const CONTACT_US = 'contact-us';
export const ECOMMERCE = 'industries/e-commerce';
export const SME = 'industries/sme';
export const RESTAURANT = 'industries/restaurant';
export const BEAUTY_AND_FASHION = 'industries/beauty-and-fashion';
export const PARTNER = 'products-and-service/partner';
export const INDEMNITY_POLICY = 'indemnitypolicy';
export const SHARING_POLICY = 'sharing-policy';
export const TERM_AND_CONDITION = 'termsandpolicies';
export const FAQ = 'faq';
export const PRIVACY_AND_POLICY = 'privacy-and-policy';
