import React from 'react';

const FacebookIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3515_1964)">
        <path
          d="M4.51438 0.0898438C2.07907 0.0898438 0.118164 2.05075 0.118164 4.48606V19.7701C0.118164 22.2054 2.07907 24.1663 4.51438 24.1663H12.7982V14.7539H10.3093V11.3651H12.7982V8.46996C12.7982 6.19534 14.2688 4.10685 17.6564 4.10685C19.0279 4.10685 20.0422 4.23853 20.0422 4.23853L19.9624 7.40308C19.9624 7.40308 18.928 7.39331 17.7993 7.39331C16.5777 7.39331 16.3818 7.95617 16.3818 8.89057V11.3652H20.0595L19.8992 14.7539H16.3818V24.1663H19.7984C22.2337 24.1663 24.1946 22.2054 24.1946 19.7701V4.48608C24.1946 2.05077 22.2337 0.0898678 19.7984 0.0898678H4.51435L4.51438 0.0898438Z"
          fill="#504B49"
        />
      </g>
      <defs>
        <clipPath id="clip0_3515_1964">
          <rect
            width="24.0764"
            height="24.0764"
            fill="white"
            transform="translate(0.118164 0.0898438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
