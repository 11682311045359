import React from 'react';

import styled from '@emotion/styled';

import PrimaryButton from '../Button/PrimaryButton';
import { ExternalLink } from '../Link/Link';

interface IHeroNoImgCard {
  header: string;
  description: string;
  btnText: string;
  btnLink: string;
  className?: string;
}
const HeroNoImgCard: React.FC<IHeroNoImgCard> = ({
  header,
  description,
  btnText,
  btnLink,
  className,
}) => {
  return (
    <Container>
      <InfoArea className={className === 'reverse' ? className : ''}>
        <Header>{header}</Header>
        <Description>{description}</Description>
        <ExternalLink to={btnLink}>
          <PrimaryButton>{btnText}</PrimaryButton>
        </ExternalLink>
      </InfoArea>
    </Container>
  );
};

export default HeroNoImgCard;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  text-align: center;
  color: ${({ theme }) => theme.palette.primaryTextColor};
  padding: 0 10rem;

  @media (max-width: 480px) {
    width: 100%;
    padding: 0rem;
  }
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 480px) {
    font-size: 2rem;
    gap: 1rem;
  }

  & > a {
    margin-top: 1rem;
    @media (max-width: 480px) {
      margin-top: 1rem;
    }
  }
`;

const Header = styled.h1`
  font-size: 3.3rem;
  font-weight: 800;

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Description = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.secondaryTextColor};

  @media (max-width: 480px) {
    font-size: 1rem;
    font-weight: 600;
  }
`;
