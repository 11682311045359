export const segmentedViewControllerTitle = [
  { title: 'Businesses' },
  { title: 'Riders' },
  { title: 'Delivery Startups' },
];

export const AccordionCellData = {
  Businesses: [
    {
      title: 'How can Darum streamline my supply chain?',
      conntent:
        'Darum optimizes your logistics with technology integration, real-time tracking, and cost-effective models tailored to your business.',
    },
    {
      title: 'What industries does Darum serve?',
      conntent:
        'Darum caters to e-commerce, healthcare, retail, manufacturing, and more. See how we can benefit your industry.',
    },
    {
      title: 'What industries does Darum serve?',
      conntent:
        'Our platform provides you with live map view, showing the exact location and ETA of your deliveries.',
    },
  ],
  Riders: [
    {
      title: 'What benefits do I get as a Darum rider?',
      conntent:
        'As a Darum rider, you’ll enjoy flexible schedules, competitive earnings, and access to a wide range of delivery opportunities.',
    },
    {
      title: 'How does Darum ensure rider safety?',
      conntent:
        'We prioritize rider safety with training programs, real-time tracking, and compliance with safety regulations.',
    },
    {
      title: 'Can I choose my preferred delivery routes?',
      conntent:
        'Yes, Darum allows riders to select routes based on preferences, helping you plan efficient and comfortable trips.',
    },
  ],
  'Delivery Startups': [
    {
      title: 'How can Darum help my startup grow?',
      conntent:
        'We offer resource sharing, dedicated support, and a reliable platform to help your startup scale efficiently.',
    },
    {
      title: 'Is it easy to onboard and train our delivery team with Darum?',
      conntent:
        'Absolutely! Darum provides comprehensive training and support materials to ensure your team is up to speed.',
    },
    {
      title: 'Can we customize the platform to fit our branding?',
      conntent:
        'Yes, Darum offers white-label solutions, allowing you to customize the platform with your branding.',
    },
  ],
};
