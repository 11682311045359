import React from 'react';

import styled from '@emotion/styled';

import { Accordion, AccordionCell } from '../Accordion/Accordion';
import { AccordionBox, AccordionBoxCell } from '../Accordion/AccordionBox';
import SectionWrapper from '../SectionWrapper/SectionWrapper';
import { AccordionCellData } from './FAQAccordionData';

const SmallScreenFAQ = () => {
  return (
    <Container>
      <SectionWrapper
        margin="2rem 0"
        title="Frequently asked Questions"
        description="Still do not get the full picture? It is ok, we got you! Please read on."
      >
        <AccordionBox>
          <AccordionBoxCell title={'Businesses'}>
            <Accordion>
              {AccordionCellData.Businesses.map((data, index) => (
                <AccordionCell key={index} title={data.title}>
                  {data.conntent}
                </AccordionCell>
              ))}
            </Accordion>
          </AccordionBoxCell>
          <AccordionBoxCell title={'Delivery Startups'}>
            <Accordion>
              {AccordionCellData['Delivery Startups'].map((data, index) => (
                <AccordionCell key={index} title={data.title}>
                  {data.conntent}
                </AccordionCell>
              ))}
            </Accordion>
          </AccordionBoxCell>
          <AccordionBoxCell title={'Riders'}>
            <Accordion>
              {AccordionCellData.Riders.map((data, index) => (
                <AccordionCell key={index} title={data.title}>
                  {data.conntent}
                </AccordionCell>
              ))}
            </Accordion>
          </AccordionBoxCell>
        </AccordionBox>
      </SectionWrapper>
    </Container>
  );
};

export default SmallScreenFAQ;

const Container = styled.div`
  display: none;

  @media (max-width: 480px) {
    display: block;
    margin-top: 4.62rem;
  }
`;
