import React from 'react';

import styled from '@emotion/styled';

import HeaderCollapseableSegment from '../../components/CollapseableSegment/HeaderCollapseableSegment';
import { FullTextLogo } from '../../components/CompanyLogo';
import CloseIcon from '../../components/Icons/CloseIcon';
import { NavLink } from '../../components/Link/Link';
import { CONTACT_US } from '../../routes/routeConstants';
import { NavDropDownData } from '../NavData';

interface IHamburgerMenu {
  open: boolean;
  toggleNav: (...arg: any) => void;
}

const HamburgerMenu: React.FC<IHamburgerMenu> = ({ open, toggleNav }) => {
  const handleNavMenuItemClick = (event: any) => {
    toggleNav();
  };
  return (
    <Container className={open ? '' : 'close'}>
      <StyledNav className={open ? '' : 'close'}>
        <LogoAndCloseBtnArea>
          <FullTextLogo className="header" />
          <CloseIconContainer
            className={open ? 'open' : ''}
            onClick={toggleNav}
          >
            <CloseIcon />
          </CloseIconContainer>
        </LogoAndCloseBtnArea>

        <NavElementContainer>
          <HeaderCollapseableSegment
            title="Products & Services"
            onClick={handleNavMenuItemClick}
            data={NavDropDownData['Products & Services']}
          />

          <HeaderCollapseableSegment
            title="Industries"
            onClick={handleNavMenuItemClick}
            data={NavDropDownData.Industries}
          />

          {/* <NavLink
            to={ABOUT_US}
            onClick={handleNavMenuItemClick}
            className="hamburger-menu"
          >
            AboutUs
          </NavLink> */}
          {/* <NavLink
            to={BLOG}
            onClick={handleNavMenuItemClick}
            className="hamburger-menu"
          >
            Blog
          </NavLink> */}
          <NavLink
            to={CONTACT_US}
            onClick={handleNavMenuItemClick}
            className="hamburger-menu"
          >
            ContactUs
          </NavLink>
        </NavElementContainer>
      </StyledNav>
    </Container>
  );
};

export default HamburgerMenu;

const Container = styled.div`
  display: None;
  height: 100vh;
  position: fixed;

  @media screen and (max-width: 480px) {
    width: 100vw;
    display: flex;
    margin-left: auto;
    z-index: 200000000;

    &.close {
      border: 2px solid yellow;
      display: none;
    }
  }
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  width: 20rem;
  margin-left: auto;
  gap: 2rem;

  padding: 2rem;
  padding-top: 1rem;
  background: white;
  overflow-x: auto;
  height: 100%;

  &.close {
    display: none;
  }
`;

const LogoAndCloseBtnArea = styled.div`
  display: flex;
  /* border: 2px solid red; */
  justify-content: space-between;
`;

const CloseIconContainer = styled.div`
  /* border: 2px solid red; */
`;

const NavElementContainer = styled.div`
  /* border: 2px solid red; */
  display: flex;
  gap: 2rem;
  flex-direction: column;
`;
