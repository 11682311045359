import React from 'react';

import styled from '@emotion/styled';

import { HeroCard } from '../../../components/Card';
import FAQ from '../../../components/FAQ.tsx/FAQ';
// import HearFromBusinessLikeYours from '../../../components/HearFromBusinessLikeYours/HearFromBusinessLikeYours';
import Page from '../../../components/Page/Page';
import ProblemsWeSolve from './ProblemsWeSolve/ProblemsWeSolve';
import SolutionsWeOffer from './SolutionsWeOffer/SolutionsWeOffer';

const Restaurant = () => {
  return (
    <Page title="Darum | Restaurant">
      <Container>
        <HeroCard
          src="/static/img/chef.png"
          header="Elevate Your Ecommerce Deliveries with Darum"
          description="Tailored Solutions for Seamless Last-Mile Logistics"
          btnLink="https://business.darum.com.ng"
          btnText="Get Started"
        />
        <SolutionsWeOffer />
        <ProblemsWeSolve />
        {/* <HearFromBusinessLikeYours /> */}
        <FAQ />
      </Container>
    </Page>
  );
};

export default Restaurant;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 3.03rem;

  @media (max-width: 480px) {
    margin-top: 1.88rem;
  }
`;
