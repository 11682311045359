import { useRoutes } from 'react-router-dom';

import LandingPageLayout from '../layout/LandingPageLayout';
import AboutUs from '../pages/AboutUs/AboutUs';
import Blog from '../pages/Blog/Blog';
import ContactUs from '../pages/ContactUs/ContactUs';
import FAQs from '../pages/FAQs/FAQs';
import Home from '../pages/Home/Home';
import IndemityPolicy from '../pages/IndemityPolicy/IndemityPolicy';
import BeautyAndFashion from '../pages/Industries/BeautyAndFashion/BeautyAndFashion';
import Ecommerce from '../pages/Industries/Ecommerce/Ecommerce';
import Restaurant from '../pages/Industries/Restaurant/Restaurant';
import SMEPage from '../pages/Industries/SMEPage/SMEPage';
import NotFound from '../pages/NotFound/NotFound';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
// import Industries from '../pages/Industries/LandingPage/Industries';
import Partner from '../pages/ProductsAndServices/Partner/Partner';
import SharingPolicy from '../pages/SharingPolicy/SharingPolicy';
import TermsAndPolicy from '../pages/TermsAndPolicy/TermsAndPolicy';
// import ProductsAndServices from '../pages/ProductsAndServices/ProductsAndServices';
import {
  ABOUT_US,
  BEAUTY_AND_FASHION,
  BLOG,
  CONTACT_US,
  ECOMMERCE,
  FAQ,
  HOME,
  INDEMNITY_POLICY,
  PARTNER,
  PRIVACY_AND_POLICY,
  RESTAURANT,
  SHARING_POLICY,
  SME,
  TERM_AND_CONDITION,
} from './routeConstants';

export default function Router() {
  return useRoutes([
    {
      path: HOME,
      element: <LandingPageLayout />,
      children: [
        { path: HOME, element: <Home /> },
        {
          path: ABOUT_US,
          element: <AboutUs />,
        },
        {
          path: CONTACT_US,
          element: <ContactUs />,
        },
        {
          path: BLOG,
          element: <Blog />,
        },
        {
          path: ECOMMERCE,
          element: <Ecommerce />,
        },
        {
          path: SME,
          element: <SMEPage />,
        },
        {
          path: RESTAURANT,
          element: <Restaurant />,
        },
        {
          path: BEAUTY_AND_FASHION,
          element: <BeautyAndFashion />,
        },
        {
          path: PARTNER,
          element: <Partner />,
        },
        {
          path: FAQ,
          element: <FAQs />,
        },
        {
          path: INDEMNITY_POLICY,
          element: <IndemityPolicy />,
        },
        {
          path: SHARING_POLICY,
          element: <SharingPolicy />,
        },
        {
          path: TERM_AND_CONDITION,
          element: <TermsAndPolicy />,
        },
        {
          path: PRIVACY_AND_POLICY,
          element: <PrivacyPolicy />,
        },
      ],
    },

    { path: '*', element: <NotFound /> },
  ]);
}
