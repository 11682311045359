import React from 'react';

import styled from '@emotion/styled';

import BeatTheTraffic from '../../../components/BeatTheTraffic/BeatTheTraffic';
import HeroNoImgCard from '../../../components/Card/HeroNoImgCard';
import FAQ from '../../../components/FAQ.tsx/FAQ';
import Page from '../../../components/Page/Page';
import WhyPartnerWithDarum from './WhyPartnerWithDarum/WhyPartnerWithDarum';

// TODO:: Where do we go to when it come to becoming a partner
const Partner = () => {
  return (
    <Page title="Darum | Partner">
      <Container>
        <HeroNoImgCard
          header="You preserve your identity, we handle the technical part of things"
          description="We bridge the now existent gap that developed due to the continued growth of e-commerce opportunities and solutions, between businesses and logistics service providers."
          btnLink="http://provider.darum.com.ng"
          btnText="Become a Partner"
        />
        <WhyPartnerWithDarum />
        <BeatTheTraffic
          title="Move better, and reach more with Darum"
          link="/"
          buttonLabel="Become a Partner"
          description="We will help you give the bigger logistics brands a run for their money, and we mean that literally."
        />
        <FAQ />
      </Container>
    </Page>
  );
};

export default Partner;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 3.03rem;

  @media (max-width: 480px) {
    margin-top: 1.88rem;
  }
`;
